// Document: https://developers.google.com/calendar/v3/reference/events/insert
export function postEvent(event) {
  return new Promise((resolve, reject) => {
    window.gapi.client.calendar.events
      .insert({
        calendarId: 'primary',
        resource: event,
      })
      .execute(function(res) {
        if (res.error) {
          reject(res.error)
        } else {
          resolve(res)
        }
      })
  })
}
