import { FieldValue } from 'firestore/common'
import moment from 'moment-timezone'
import { notifications } from 'utils/config'

export const search = ({ db, event, me, uids }) => {
  const start = moment(event.start.toDate())
  const end = moment(event.end.toDate())
  const hours = start.hours()
  const day = start.day()
  const isWeekday = ![0, 6].includes(day)
  const isNoon = hours < 18 && hours >= 6
  const field = (() => {
    if (isWeekday) {
      return isNoon ? 'weekdayNoon' : 'weekdayNight'
    }
    return isNoon ? 'holidayNoon' : 'holidayNight'
  })()

  let ref = db.collection('users').where('freeTime', 'array-contains', field)

  console.log('a')
  // 検索対象の絞り込みをしていた場合
  if (me.sexFileter && [1, 2].includes(me.sexFilter.length)) {
    console.log('b')
    ref = ref.where('sex', 'in', me.sexFilter)
  }

  return ref
    .orderBy('lastLoginAt', 'desc')
    .limit(100)
    .get()
    .then(({ docs }) => {
      console.log(docs)
      let users = docs
        .map(doc => doc.data())
        // 自分を除外
        .filter(user => user.uid !== me.uid)
        // busy を考慮
        .filter(user => {
          const match = user.busy.find(busy => {
            return moment(busy.start) < end && moment(busy.end) > start
          })
          return match === undefined
        })
        // 毎回同じ順番に表示されないようにランダムソート
        .sort((a, b) => {
          return Math.random() > 0.5 ? 1 : -1
        })

      // Recommend を指定した場合は、行きたい人を優先的に表示
      if (uids) {
        users = users.sort((a, b) => {
          const A = uids.includes(a.uid)
          const B = uids.includes(b.uid)
          if (A && !B) return -1
          if (!A && B) return 1
          return 0
        })
      }

      return users.slice(0, 9)
    })
}

export const get = ({ db, uid }) => {
  return db
    .collection('users')
    .doc(uid)
    .get()
    .then(doc => doc.data())
}

export const list = ({ db, uids }) => {
  return db
    .collection('users')
    .where('uid', 'in', uids)
    .get()
    .then(({ docs }) => docs.map(doc => doc.data()))
}

export const updateLastLoginAt = ({ db, uid }) => {
  return db
    .collection('users')
    .doc(uid)
    .update({ lastLoginAt: FieldValue.serverTimestamp() })
}

export const update = ({ db, user, uid }) => {
  return db
    .collection('users')
    .doc(uid)
    .update(user)
}

export const getSettings = ({ db, uid }) => {
  return db
    .collection('users')
    .doc(uid)
    .collection('settings')
    .doc(uid)
    .get()
    .then(doc => doc.data())
}

export const updateSettings = ({ db, data, uid }) => {
  return db
    .collection('users')
    .doc(uid)
    .collection('settings')
    .doc(uid)
    .update(data)
}

export const addProviderData = async ({ db, data, uid }) => {
  const docRef = db.collection('users').doc(uid)

  const userDoc = await docRef.get()

  if (!userDoc.exists) {
    const userData = {
      uid: uid,
      name: data.displayName,
      photoURL: data.photoURL,
      busy: [],
      createdAt: FieldValue.serverTimestamp(),
      lastLoginAt: FieldValue.serverTimestamp(),
    }
    await docRef.set(userData)
  }

  const providers = await docRef
    .collection('providerData')
    .get()
    .then(({ docs }) => docs.map(doc => doc.data()))

  if (!providers.find(obj => obj.providerId === data.providerId)) {
    await docRef.collection('providerData').add(data)
  }

  const settingDoc = await docRef
    .collection('settings')
    .doc(uid)
    .get()

  if (!settingDoc.exists) {
    await docRef
      .collection('settings')
      .doc(uid)
      .set({
        email: data.email,
        notifications: notifications.map(notification => notification.value),
      })
  }

  return uid
}
