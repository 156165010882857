import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import StarIcon from '@material-ui/icons/Star'
import PersonIcon from '@material-ui/icons/Person'

import { useHistory } from 'hooks/useHistory'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    borderTop: '1px solid #ddd',
  },
}))

const tabs = [
  {
    path: '/recommends',
    name: 'さがす',
    icon: <StarIcon />,
  },
  {
    path: '/events',
    name: 'マイイベント',
    icon: <PersonIcon />,
  },
  {
    path: '/messages',
    name: 'メッセージ',
    icon: <QuestionAnswerIcon />,
  },
]

export default function TabBar() {
  const classes = useStyles()
  const history = useHistory()
  const [tabIndex, setTabIndex] = useState(() => {
    const index = tabs.findIndex(tab => {
      return history.location.pathname.startsWith(tab.path)
    })
    return index !== -1 ? index : 0
  })

  const handleChangeTab = (event, index) => {
    setTabIndex(index)
    history.replace(tabs[index].path)
  }

  useEffect(() => {
    const index = tabs.findIndex(tab => {
      return history.location.pathname.startsWith(tab.path)
    })
    if (index === tabIndex) return
    setTabIndex(index)
  }, [history.location.pathname, tabIndex])

  return (
    <BottomNavigation
      value={tabIndex}
      onChange={handleChangeTab}
      showLabels
      className={classes.root}
    >
      {tabs.map(tab => (
        <BottomNavigationAction key={tab.path} label={tab.name} icon={tab.icon} />
      ))}
    </BottomNavigation>
  )
}
