import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    padding: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default function Error() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography color="textPrimary" align="center">
        アクセスしたページを
        <br />
        閲覧する権限がありません
      </Typography>
    </div>
  )
}
