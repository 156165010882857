import React, { useMemo, useState } from 'react'
import Markdown from 'react-markdown'
import moment from 'moment-timezone'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import AddIcon from '@material-ui/icons/Add'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DateRangeIcon from '@material-ui/icons/DateRange'
import PublicIcon from '@material-ui/icons/Public'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import LocationIcon from '@material-ui/icons/Room'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import LabelIcon from '@material-ui/icons/Label'
import Button from '@material-ui/core/Button'

import { useFirebaseApp } from 'hooks/useFirebaseApp'
import { useAuth } from 'hooks/useAuth'
import { FieldValue } from 'firestore/common'
import { useHistory } from 'hooks/useHistory'

const useStyles = isPage =>
  makeStyles(theme => ({
    list: {
      borderRadius: isPage ? 0 : theme.spacing(2),
      padding: 0,
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    panel: {
      flex: 1,
      boxShadow: 'none',
      '&:before': {
        height: 0,
      },
    },
    panelSummary: {
      padding: 0,
    },
    panelIcon: {
      marginRight: 32,
    },
    panelDetails: {
      paddingTop: 0,
      paddingLeft: 56,
    },
    weeklySchedule: {
      listStyle: 'none',
    },
    listItem: {
      background: '#fff',
      borderBottom: '1px solid #ddd',
    },
    onelineText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    summary: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: theme.spacing(0.5),
    },
    category: {
      color: '#fff',
      background: theme.palette.primary.main,
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.spacing(0.5),
    },
    date: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    tag: {
      display: 'inline-block',
      background: '#888',
      color: '#fff',
      padding: theme.spacing(0.5, 1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
    submit: {
      padding: theme.spacing(1, 2),
      borderTop: '1px solid #ddd',
      background: '#f8f8f8',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    button: {
      margin: theme.spacing(1, 1, 1, 0),
      minWidth: 100,
    },
  }))

export default function Recommend({ recommend: _recommend, isPage }) {
  const [expanded, setExpanded] = useState(false)
  const [recommend, setRecommend] = useState(_recommend)
  const history = useHistory()
  const { isSignedIn, user, signIn } = useAuth()
  const { RecommendData, photoURLWithUid } = useFirebaseApp()
  const classes = useStyles(isPage)()

  const term = useMemo(() => {
    if (recommend.isYearAround) return '通年開催'

    if (!recommend.date.start && !recommend.date.end) return '開催期間未定'

    if (!recommend.date.start && recommend.date.end) {
      return moment(recommend.date.end.toDate()).format('YYYY/MM/DD') + ' まで開催'
    }

    if (recommend.date.start && !recommend.date.end) {
      return moment(recommend.date.start.toDate()).format('YYYY/MM/DD') + ' から開催'
    }

    // 開始・終了日がある場合
    return (
      moment(recommend.date.start.toDate()).format('YYYY/MM/DD') +
      ' - ' +
      moment(recommend.date.end.toDate()).format('MM/DD')
    )
  }, [recommend])

  const avatars = useMemo(() => {
    if (!recommend) return null
    const likes = recommend.likes.slice()
    return likes.reverse().slice(0, 5)
  }, [recommend])

  const weekOfDays = [
    { key: 'mon', label: '月曜' },
    { key: 'tue', label: '火曜' },
    { key: 'wed', label: '水曜' },
    { key: 'thu', label: '木曜' },
    { key: 'fri', label: '金曜' },
    { key: 'sat', label: '土曜' },
    { key: 'sun', label: '日曜' },
    { key: 'hol', label: '祝日' },
  ]

  const weeklySchedule = weekOfDays.map(({ key, label }) => {
    if (!recommend[key].start && !recommend[key].end) {
      return <li key={key}>{label} 営業時間外</li>
    }
    return (
      <li key={key}>
        {label} {recommend[key].start} - {recommend[key].end}
      </li>
    )
  })

  const handleClickLike = () => {
    if (!user) return
    const data = {
      likes: recommend.likes.includes(user.uid)
        ? FieldValue.arrayRemove(user.uid)
        : FieldValue.arrayUnion(user.uid),
    }
    RecommendData.update({ id: recommend.id, data }).then(setRecommend)
  }

  const tags = useMemo(() => {
    return recommend.tags.map(tag => {
      return (
        <Typography key={tag} variant="caption" className={classes.tag}>
          {tag}
        </Typography>
      )
    })
  }, [recommend, classes])

  const date = useMemo(() => {
    if (recommend.category === '動画') return null
    // date.text が入力された場合はそちらを優先して表示
    if (recommend.date.text) {
      return (
        <ListItem className={classes.listItem}>
          <ListItemIcon>
            <DateRangeIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={recommend.date.text} />
        </ListItem>
      )
    }
    return (
      <ListItem className={classes.listItem + ' ' + classes.date}>
        <ExpansionPanel
          className={classes.panel}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.panelSummary}
            id="date-panel"
          >
            <DateRangeIcon className={classes.panelIcon} color="primary" />
            <Typography color="textPrimary">{term}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.panelDetails}>
            <ul className={classes.weeklySchedule}>{weeklySchedule}</ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </ListItem>
    )
  }, [recommend, classes, expanded, term, weeklySchedule])

  const button = useMemo(() => {
    if (!isPage) return null
    if (isSignedIn === null) return null

    return isSignedIn ? (
      <div className={classes.submit}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() =>
            history.push({
              pathname: '/events/new',
              state: { recommend },
            })
          }
        >
          誘う
        </Button>
        <Typography
          style={{ flex: 1, fontWeight: 'bold', textAlign: 'center', color: '#fb8c00' }}
          variant="body1"
        >
          一緒に行く人を
          <br />
          募集しましょう
        </Typography>
      </div>
    ) : (
      <div className={classes.submit}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ minWidth: 150 }}
          onClick={signIn}
        >
          ログインして誘う
        </Button>
        <Typography
          style={{ flex: 1, fontWeight: 'bold', textAlign: 'center', color: '#fb8c00' }}
          variant="body1"
        >
          一緒に行く人を
          <br />
          募集しましょう
        </Typography>
      </div>
    )
  }, [isPage, isSignedIn, classes, signIn, history, recommend])

  return (
    <>
      <List itemScope itemType="http://schema.org/Article" className={classes.list}>
        <img
          itemProp="image"
          src={recommend.imageURL}
          alt={recommend.summary}
          style={{
            width: '100%',
            height: 168,
            objectFit: 'cover',
          }}
        />
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={
              <Typography className={classes.summary} variant="h1" itemProp="headline">
                {recommend.summary}
              </Typography>
            }
            secondary={
              <Typography className={classes.category} component="span" variant="caption">
                {recommend.category}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={handleClickLike}>
              {user && recommend.likes.includes(user.uid) ? (
                <>
                  <FavoriteIcon style={{ fontSize: 20 }} color="primary" />
                  <Typography
                    style={{ fontSize: 16, paddingLeft: 2 }}
                    component="span"
                    color="primary"
                  >
                    {Math.min(recommend.likes.length, 999)}
                  </Typography>
                </>
              ) : (
                <>
                  <FavoriteBorderIcon style={{ fontSize: 20 }} />
                  <Typography style={{ fontSize: 16, paddingLeft: 2 }} component="span">
                    {Math.min(recommend.likes.length, 999)}
                  </Typography>
                  {user && <AddIcon style={{ fontSize: 16 }} />}
                </>
              )}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {isPage && recommend.likes.length > 0 && (
          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <FavoriteIcon color="primary" />
            </ListItemIcon>
            <ListItemAvatar style={{ flex: 1 }}>
              <AvatarGroup>
                {avatars.map(uid => (
                  <Avatar
                    className={classes.avatar}
                    key={uid}
                    alt={uid}
                    src={photoURLWithUid(uid)}
                  />
                ))}
                {recommend.likes.length > 5 && (
                  <Avatar className={classes.avatar}>+{recommend.likes.length - 3}</Avatar>
                )}
              </AvatarGroup>
            </ListItemAvatar>
            {user && (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={() =>
                    history.push({
                      pathname: `/recommends/${recommend.id}/likes`,
                      state: recommend,
                    })
                  }
                >
                  <NavigateNextIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        )}
        {recommend.location && (
          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <LocationIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              secondary={
                <Typography color="textPrimary" variant="body2" className={classes.onelineText}>
                  {recommend.location}
                </Typography>
              }
            />
          </ListItem>
        )}
        {date}
        <ListItem
          className={classes.listItem}
          component="a"
          href={recommend.website.url}
          target="_blank"
        >
          <ListItemIcon>
            <PublicIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            className={classes.onelineText}
            primary={
              <Typography color="textPrimary" variant="body2" className={classes.onelineText}>
                {recommend.website.url}
              </Typography>
            }
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={<Markdown source={recommend.description} className="markdown-body" />}
          />
        </ListItem>
        {tags.length > 0 && (
          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <LabelIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={tags} />
          </ListItem>
        )}
      </List>
      {button}
    </>
  )
}
