export const FIRESTORE = {
  LIMIT: 20, // firestore の list で取得する数
}

export const env = (() => {
  if (process.env.NODE_ENV === 'development') {
    return 'development'
  }
  if (window.location.host.split('.')[0] === 'stg-lachsy') {
    return 'staging'
  }
  return 'production'
})()

export const firebaseConfig =
  env === 'production'
    ? {
        apiKey: 'AIzaSyDwZtVr4RhrgLjevu5n3sB_dTcoVgPA_ys',
        authDomain: 'schead-pj.firebaseapp.com',
        databaseURL: 'https://schead-pj.firebaseio.com',
        projectId: 'schead-pj',
        storageBucket: 'schead-pj.appspot.com',
        messagingSenderId: '403918570081',
        appId: '1:403918570081:web:a21e8a166e4930dc37a020',
        measurementId: 'G-T6DPH98DE6',
      }
    : {
        apiKey: 'AIzaSyCcUZs8f5DvI73rssnaHhlZiIuk3A6lk_Q',
        authDomain: 'stg-lachsy.firebaseapp.com',
        databaseURL: 'https://stg-lachsy.firebaseio.com',
        projectId: 'stg-lachsy',
        storageBucket: 'stg-lachsy.appspot.com',
        messagingSenderId: '159302117192',
        appId: '1:159302117192:web:ea1b5fc3702db8c8d5357d',
      }

export const gapiConfig = (() => {
  const common = {
    scope:
      'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly',
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
  }
  return env === 'production'
    ? {
        ...common,
        clientId: '403918570081-igsl4cvnbk98b6eem45lcg876e0o9k1d.apps.googleusercontent.com',
        apiKey: 'AIzaSyDwZtVr4RhrgLjevu5n3sB_dTcoVgPA_ys',
      }
    : {
        ...common,
        clientId: '159302117192-osvq8vi6iq2dqma8rf7rljp0u1gk6s5k.apps.googleusercontent.com',
        apiKey: 'AIzaSyCcUZs8f5DvI73rssnaHhlZiIuk3A6lk_Q',
      }
})()

export const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
]

export const jobs = [
  '上場企業',
  '大手商社',
  '外資金融',
  '銀行・証券',
  '生保・損保',
  '外資コンサル',
  '国家公務員',
  '経営者・役員',
  '医師',
  '歯科医師',
  '薬剤師',
  '弁護士',
  '公認会計士',
  '司法書士・行政書士',
  'パイロット',
  'スポーツ関連',
  '看護師',
  '歯科衛生士',
  '客室乗務員',
  '芸能・モデル',
  '秘書',
  '受付',
  '美容関係',
  '保育士',
  '会社員',
  'ITエンジニア',
  'アパレル・販売',
  '接客業',
  '事務',
  'ブライダル',
  '調理師・栄養士',
  '製薬',
  'メーカー',
  '地方公務員',
  '教育関連',
  'インターネット・通信',
  '旅行関係',
  'マスコミ・広告・出版',
  '建築・インテリア',
  '不動産',
  'インフラ',
  'フリーランス',
  '学生',
  '福祉・介護',
  'その他',
]

export const sexList = [
  { label: '男性', value: 'male' },
  { label: '女性', value: 'female' },
  { label: 'その他', value: 'other' },
]

export const times = [
  { label: '平日昼', value: 'weekdayNoon' },
  { label: '土日祝昼', value: 'holidayNoon' },
  { label: '平日夜', value: 'weekdayNight' },
  { label: '土日祝夜', value: 'holidayNight' },
]

export const privacyStatusList = [
  { label: '相手を選んで募集', value: 'private' }, // 非公開
  { label: 'URL で友達を招待', value: 'unlisted' }, // 限定公開
  { label: '全体に公開', value: 'public' }, // 公開
]

export const notifications = [
  { label: 'マッチング成立時', value: 'matching' },
  { label: '招待された時', value: 'invitation' },
  { label: 'チャットの新着メッセージ', value: 'message' },
  { label: '作成したイベントへの参加申込', value: 'participation' },
  { label: '参加承認', value: 'acceptance' },
]
