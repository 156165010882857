import React, { useMemo, useState, useEffect } from 'react'
import moment from 'moment-timezone'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import ProfileInfoList from 'components/ProfileInfoList'
import RecommendListItem from 'components/RecommendListItem'
import { useFirebaseApp } from 'hooks/useFirebaseApp'
import Recommend from 'components/Recommend'
import Popup from 'components/Popup'

const useStyles = makeStyles(theme => ({
  profile: {
    background: `linear-gradient(-135deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
    borderRadius: 0,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    borderRadius: '50%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  listItem: {
    borderBottom: '1px solid #ddd',
  },
  name: {
    marginBottom: theme.spacing(1),
    color: '#fff',
  },
  submit: {
    position: 'sticky',
    bottom: 0,
    padding: theme.spacing(2, 2, 2, 2),
    borderTop: '1px solid #ddd',
    background: '#f8f8f8',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}))

export default function Profile({ user: _user, buttons }) {
  const [user, setUser] = useState(null)
  const [attended, setAttended] = useState(null)
  const [likes, setLikes] = useState(null)
  const [tab, setTab] = useState(0)
  const [activeRecommend, setActiveRecommend] = useState(null)
  const { UserData, RecommendData } = useFirebaseApp()
  const classes = useStyles()

  useEffect(() => {
    if (_user) {
      if (_user.introduction) {
        setUser(_user)
      } else {
        UserData.get({ uid: _user.uid }).then(result => setUser(result))
      }
    } else {
      setUser(null)
    }
  }, [_user, UserData])

  useEffect(() => {
    if (!user) return
    RecommendData.search({
      queries: [{ field: 'participants', operator: 'array-contains', value: user.uid }],
    }).then(setAttended)
  }, [user, RecommendData])

  useEffect(() => {
    if (!user) return
    RecommendData.search({
      queries: [{ field: 'likes', operator: 'array-contains', value: user.uid }],
    }).then(setLikes)
  }, [user, RecommendData])

  const content = useMemo(() => {
    if (!user) return null
    switch (tab) {
      case 0:
        return <ProfileInfoList user={user} />
      case 1:
        if (attended && attended.length === 0) {
          return (
            <ListItem>
              <ListItemText primary="まだ参加したイベントはありません" />
            </ListItem>
          )
        }
        return (attended || []).map(recommend => (
          <RecommendListItem
            key={recommend.id}
            recommend={recommend}
            handleClick={() => setActiveRecommend(recommend)}
          />
        ))
      case 2:
        if (likes && likes.length === 0) {
          return (
            <ListItem>
              <ListItemText primary="まだ保存したイベントはありません" />
            </ListItem>
          )
        }
        return (likes || []).map(recommend => (
          <RecommendListItem
            key={recommend.id}
            recommend={recommend}
            handleClick={() => setActiveRecommend(recommend)}
          />
        ))
      default:
        return null
    }
  }, [tab, user, attended, likes])

  const submit = buttons.map((button, index) => {
    if (!user) return null
    return (
      <Button
        key={index}
        variant="contained"
        color={button.color || 'primary'}
        className={classes.button}
        onClick={() => button.handleClick(user.uid)}
      >
        {button.label}
      </Button>
    )
  })

  if (!user) return null

  return (
    <>
      <div className={classes.profile}>
        <Avatar className={classes.avatar} src={user.photoURL} alt={user.name} />
        <Typography className={classes.name} variant="h5">
          {user.name}
        </Typography>
        {user.lastLoginAt && (
          <Typography variant="caption" style={{ color: '#fff' }}>
            {moment(user.lastLoginAt.toDate()).fromNow()}にログイン
          </Typography>
        )}
      </div>
      <AppBar position="sticky" color="inherit">
        <Tabs
          value={tab}
          onChange={(e, v) => setTab(v)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="プロフィール" />
          <Tab label="参加イベント" />
          <Tab label="興味あり" />
        </Tabs>
      </AppBar>
      <List>{content}</List>
      {buttons.length > 0 && <div className={classes.submit}>{submit}</div>}
      <Popup open={activeRecommend !== null} handleClose={() => setActiveRecommend(null)}>
        {activeRecommend !== null && <Recommend recommend={activeRecommend} isPage={false} />}
      </Popup>
    </>
  )
}
