import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#fff',
    flex: 1,
    height: window.innerHeight - 170,
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default function CenteredRoot({ style, children }) {
  const classes = useStyles()
  return (
    <div style={{ ...(style || {}) }} className={classes.root}>
      {children}
    </div>
  )
}
