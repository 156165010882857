import React, { useEffect, useState, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useFirebaseApp } from 'hooks/useFirebaseApp'
import { useAuth } from 'hooks/useAuth'
import { useHistory } from 'hooks/useHistory'
import UserList from 'components/UserList'

export default function EventMemberList() {
  const { id } = useParams()
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)
  const [event, setEvent] = useState(null)
  const [attendees, setAttendees] = useState([])
  const { EventData } = useFirebaseApp()
  const { UserData } = useFirebaseApp()
  const { user } = useAuth()
  const { state } = useLocation()

  const canSubmit = useMemo(() => {
    if (!event || !user || submitting) return false
    if (event.privacyStatus === 'private') {
      return event.state === 'created' && event.creator.uid === user.uid
    }
    // 公開、限定公開
    return event.creator.uid === user.uid && event.memberIds.length < event.limit
  }, [event, user, submitting])

  useEffect(() => {
    if (id) {
      EventData.get({ id }).then(setEvent)
    }
  }, [id, EventData])

  // 非公開イベントでは作成者以外閲覧不可
  useEffect(() => {
    if (event && user && event.privacyStatus === 'private' && event.creator.uid !== user.uid) {
      history.push(`/events/${id}`)
    }
  }, [event, user, id, history])

  useEffect(() => {
    if (!event || !user) return
    if (event.privacyStatus === 'private' && event.state === 'created') {
      const conditions = { event, me: user }
      if (state && state.recommend && state.recommend.likes.length > 0) {
        conditions.uids = state.recommend.likes
      }
      UserData.search(conditions).then(setAttendees)
    } else {
      setAttendees(
        Object.values(event.attendees).filter(({ uid }) => !event.memberIds.includes(uid))
      )
    }
  }, [event, user, state, UserData])

  const onSubmit = async selected => {
    setSubmitting(true)
    let data

    if (event.privacyStatus === 'private') {
      let users = event.attendees
      selected
        .map(uid => attendees.find(u => u.uid === uid))
        .forEach(({ uid, name, photoURL }) => {
          users[uid] = { uid, name, photoURL }
        })
      data = {
        attendeeIds: Object.keys(users),
        attendees: users,
        state: 'invited',
      }
    } else {
      let users = event.members
      selected
        .map(uid => attendees.find(u => u.uid === uid))
        .forEach(({ uid, name, photoURL }) => {
          users[uid] = { uid, name, photoURL }
        })
      data = {
        memberIds: Object.keys(users),
        members: users,
        state: 'matched',
      }
    }
    const response = await EventData.update({ data, id })

    setEvent(response)
    setSubmitting(false)
  }

  const submitText = useMemo(() => {
    if (!event) return null
    if (event.privacyStatus !== 'private') return '参加を承諾する'
    return event.state === 'created' ? '招待する' : '招待済み'
  }, [event])

  if (!event) return null

  return (
    <UserList
      users={[
        {
          label: '参加者一覧',
          values: Object.values(event.members),
          disabled: true,
        },
        {
          label: '候補者一覧',
          values: Object.values(attendees),
          disabled: !canSubmit,
        },
      ]}
      title="候補者一覧"
      handleSubmit={onSubmit}
      submitText={submitText}
      canSubmit={canSubmit}
    />
  )
}
