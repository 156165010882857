import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import Profile from 'components/Profile'
import { useHistory } from 'hooks/useHistory'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      paddingTop: 0,
    },
  },
  iconButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    background: 'rgb(0,0,0,0.3)',
  },
  icon: {
    color: 'rgb(255,255,255,0.7)',
  },
}))

export default function User() {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const { id } = useParams()
  const history = useHistory()

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={() => history.goBack()}
      fullScreen
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'left' }}
    >
      <DialogContent className={classes.content}>
        <Profile user={{ uid: id }} buttons={[]} />
        <IconButton size="small" className={classes.iconButton} onClick={() => setOpen(false)}>
          <ArrowBackIcon className={classes.icon} />
        </IconButton>
      </DialogContent>
    </Dialog>
  )
}
