import React, { useState, useRef, useEffect, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import Typography from '@material-ui/core/Typography'

import placeholder from 'images/placeholder.jpg'
import { useFirebaseApp } from 'hooks/useFirebaseApp'

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  camera: {
    position: 'absolute',
    width: theme.spacing(4),
    height: theme.spacing(4),
    top: '50%',
    transform: 'translateY(-50%)',
  },
}))

export default function ImageSelector({
  size,
  isAvatar,
  value,
  path,
  error,
  handleChange,
  buffer,
}) {
  const classes = useStyles()
  const canvas = useRef(null)
  const { storage } = useFirebaseApp()
  const [submitting, setSubmitting] = useState(false)

  const rect = useCallback(
    (w, h) => {
      const r = size.height / size.width
      if (h > w * r) {
        return {
          x: 0,
          y: (h - w * r) / 2,
          w: w,
          h: w * r,
        }
      } else {
        return {
          x: (w - h / r) / 2,
          y: 0,
          w: h / r,
          h: h,
        }
      }
    },
    [size]
  )

  const submit = useCallback(
    url => {
      if (!canvas) return
      setSubmitting(true)
      const image = new Image()
      const context = canvas.current.getContext('2d')
      image.src = url
      image.onload = async () => {
        // Canvas に描画して解像度を 200px * 200px に落とす
        const r = rect(image.width, image.height)
        const { width, height } = canvas.current
        context.drawImage(image, r.x, r.y, r.w, r.h, 0, 0, width, height)
        const dataURL = canvas.current.toDataURL('image/jpeg', 0.5)

        // Storage にアップロード
        const imageRef = storage.child(path)
        const snapshot = await imageRef.putString(dataURL, 'data_url')
        const url = await snapshot.ref.getDownloadURL()
        handleChange(url)
        setSubmitting(false)
      }
    },
    [handleChange, path, rect, storage, canvas]
  )

  useEffect(() => {
    if (!buffer || submitting) return
    const blob = new Blob([buffer], { type: 'image/jpeg' })
    const url = window.URL.createObjectURL(blob)
    submit(url)
  }, [buffer, submit, submitting])

  const onChange = ({ target }) => {
    const reader = new FileReader()
    if (target.files.length === 0) return
    reader.readAsDataURL(target.files[0])
    reader.onload = e => submit(e.target.result)
  }

  return (
    <div style={{ flex: 1 }}>
      <input
        id="image-selector"
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={onChange}
      />
      <label htmlFor="image-selector" style={{ display: 'block', width: '100%' }}>
        <IconButton component="span" style={{ width: '100%', padding: 0 }}>
          <canvas
            id="canvas"
            ref={canvas}
            width={size.width}
            height={size.height}
            style={{ display: 'none' }}
          />
          {isAvatar ? (
            <Avatar alt="プロフィール画像" src={value} className={classes.avatar} />
          ) : (
            <img
              alt="イベント画像"
              src={value || placeholder}
              style={{ width: '100%', height: 'auto' }}
            />
          )}
          <PhotoCamera className={classes.camera} />
        </IconButton>
      </label>
      {error && (
        <Typography color="error" variant="caption">
          {error.message}
        </Typography>
      )}
    </div>
  )
}
