import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  rect: {
    width: 80,
    height: 60,
  },
}))

export default function ListItemImage({ src, alt, style }) {
  const classes = useStyles()
  return (
    <div className={classes.rect} style={{ ...style, marginRight: 8 }}>
      <img
        className={classes.rect}
        style={{ ...style, objectFit: 'cover' }}
        alt={alt}
        src={src || '/event.png'}
        onError={event => (event.target.src = '/event.png')}
      />
    </div>
  )
}
