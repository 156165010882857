import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Route, useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import LikeList from 'pages/LikeList'
import RecommendComponent from 'components/Recommend'
import { useFirebaseApp } from 'hooks/useFirebaseApp'
import { useHistory } from 'hooks/useHistory'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      paddingTop: 0,
    },
  },
  backButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    background: 'rgb(0,0,0,0.3)',
  },
  backIcon: {
    color: 'rgb(255,255,255,0.7)',
  },
}))

export default function Recommend() {
  const classes = useStyles()
  const history = useHistory()
  const [recommend, setRecommend] = useState(null)
  const { id } = useParams()
  const { RecommendData } = useFirebaseApp()

  useEffect(() => {
    if (id && !recommend) {
      RecommendData.get({ id }).then(data => {
        setRecommend(data)
      })
    }
  }, [id, RecommendData, recommend])

  const description = useMemo(() => {
    if (!recommend) return null

    // ### で始まる description は ### 以降の一文を返す
    const matches = recommend.description.match('^### (.*)\n')
    if (matches) {
      return matches[1]
    }

    // '。' で文章を分割して 90 文字以内におさまるところまでの文章を返す
    const paragraphs = recommend.description.split('。')
    let str = ''
    for (let i = 0; i < paragraphs.length; i++) {
      const p = paragraphs[i]
      if ((str + p).length > 90) break
      str += `${p}。`
    }
    return str
  }, [recommend])

  if (!recommend) {
    return <div style={{ flex: 1 }} />
  }

  return (
    <>
      {recommend && (
        <Helmet>
          <title>{recommend.summary} | Lachsy</title>
          {description && <meta name="description" content={description} />}
        </Helmet>
      )}
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1100,
        }}
      >
        <RecommendComponent recommend={recommend} isPage={true} />
        <IconButton size="small" className={classes.backButton} onClick={() => history.goBack()}>
          <ArrowBackIcon className={classes.backIcon} />
        </IconButton>
      </div>
      <Route path="/recommends/:id/likes" component={LikeList} />
    </>
  )
}
