import React, { useState, useContext, createContext, useCallback } from 'react'

import { useFirebaseApp } from 'hooks/useFirebaseApp'
import { FIRESTORE } from 'utils/config'

const RecommendContext = createContext()

export function RecommendProvider({ children }) {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const { RecommendData, EventData } = useFirebaseApp()

  const load = useCallback(
    category => {
      setLoading(true)
      const func = category === '募集中' ? EventData.list : RecommendData.list
      func({
        queries: ['ピックアップ', '募集中'].includes(category)
          ? null
          : [{ field: 'category', operator: '==', value: category }],
      }).then(docs => {
        setData(current => {
          current[category] = {
            items: docs,
            hasMore: docs.length === FIRESTORE.LIMIT,
          }
          return current
        })
        setLoading(false)
      })
    },
    [RecommendData, EventData]
  )

  const loadMore = useCallback(
    category => {
      const { items, hasMore } = data[category]
      if (hasMore) {
        setLoading(true)
        const func = category === '募集中' ? EventData.list : RecommendData.list
        func({
          queries: ['ピックアップ', '募集中'].includes(category)
            ? null
            : [{ field: 'category', operator: '==', value: category }],
          startAfter: items[items.length - 1],
        }).then(docs => {
          setData(current => {
            current[category] = {
              items: items.concat(docs),
              hasMore: docs.length === FIRESTORE.LIMIT,
            }
            return current
          })
          setLoading(false)
        })
      }
    },
    [RecommendData, EventData, data]
  )

  return <RecommendContext.Provider value={{ data, load, loadMore, loading }} children={children} />
}

export function useRecommend() {
  return useContext(RecommendContext)
}
