import React from 'react'
import { Switch, Route } from 'react-router-dom'

import AddButton from 'components/AddButton'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useAuth } from 'hooks/useAuth'
import { useEvent } from 'hooks/useEvent'
import { useHistory } from 'hooks/useHistory'
import Event from 'pages/Event'
import EventListItem from 'components/EventListItem'
import CenteredRoot from 'components/CenteredRoot'
import NotLogin from 'components/NotLogin'

export default function InvitationList() {
  const { user, isSignedIn } = useAuth()
  const { events, invitations } = useEvent()
  const history = useHistory()

  const handleClickNewEvent = () => {
    history.push({ pathname: '/events/new' })
  }

  const handleClickEvent = event => {
    if (event.state === 'declined') return
    history.push({ pathname: `/events/${event.id}`, state: event })
  }

  const handleClickInvitation = event => {
    if (event.attendees[user.uid].accept === false) return
    history.push({ pathname: `/events/${event.id}`, state: event })
  }

  const view = (() => {
    if (isSignedIn === false) {
      return <NotLogin event />
    }

    if (events === null) {
      return (
        <CenteredRoot>
          <CircularProgress />
        </CenteredRoot>
      )
    }

    return (
      <>
        <List>
          <ListSubheader disableSticky={true}>作成したイベント</ListSubheader>
          {events.length > 0 ? (
            events.map(e => {
              return (
                <EventListItem key={e.id} event={e} user={user} handleClick={handleClickEvent} />
              )
            })
          ) : (
            <ListItem>
              <ListItemText primary="まだ作成していません。" />
            </ListItem>
          )}
          <ListSubheader disableSticky={true}>招待されたイベント</ListSubheader>
          {invitations.length > 0 ? (
            invitations.map(e => {
              return (
                <EventListItem
                  key={e.id}
                  event={e}
                  user={user}
                  handleClick={handleClickInvitation}
                />
              )
            })
          ) : (
            <ListItem>
              <ListItemText primary="まだ招待はありません。" />
            </ListItem>
          )}
        </List>
        <AddButton
          onClick={handleClickNewEvent}
          showTutorialText={events.length === 0 && user && user.busy}
        />
      </>
    )
  })()

  return (
    <>
      {view}
      <Switch>
        <Route path="/events/:id" component={Event} />
      </Switch>
    </>
  )
}
