import React from 'react'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  primaryText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  lastMessage: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  badge: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#fff',
    background: theme.palette.primary.main,
    padding: '4px 7px',
    borderRadius: theme.spacing(2),
  },
  listRight: {
    minWidth: theme.spacing(6),
    marginTop: '6px',
    textAlign: 'center',
  },
}))

export default function ChatListItem({ event, uid, handleClick }) {
  const classes = useStyles()
  const friend = Object.values(event.members).find(member => member.uid !== uid)
  const { id, summary, lastMessage: message, members } = event
  const hasUnread =
    message &&
    members[uid].lastViewAt &&
    message.createdAt.seconds > members[uid].lastViewAt.seconds
  const date =
    message && message.createdAt ? moment(message.createdAt.toDate()).format('HH:mm') : ''

  return (
    <ListItem
      alignItems="flex-start"
      id={id}
      onClick={handleClick}
      style={{ borderBottom: '1px solid #ddd' }}
      button
    >
      <ListItemAvatar>
        <Avatar alt={friend.name} src={friend.photoURL} />
      </ListItemAvatar>
      <ListItemText
        style={{ flex: 1 }}
        primary={
          <Typography className={classes.primaryText} variant="body2" color="textPrimary">
            {summary}
          </Typography>
        }
        secondary={
          <Typography className={classes.lastMessage} variant="caption" color="textSecondary">
            {message ? message.text : 'メッセージを送ろう'}
          </Typography>
        }
      />
      <div className={classes.listRight}>
        <Typography className={classes.lastMessage} variant="caption" color="textSecondary">
          {date}
        </Typography>
        {hasUnread && (
          <Typography variant="caption" className={classes.badge}>
            NEW
          </Typography>
        )}
      </div>
    </ListItem>
  )
}
