import React, { useState, useEffect, useContext, createContext } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/auth'

import { useFirebaseApp } from 'hooks/useFirebaseApp'
import { gapiConfig } from 'utils/config'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const { UserData } = useFirebaseApp()
  const [user, setUser] = useState(null) // Firestore に格納された UserData
  const [credential, setCredential] = useState(null) // Google API の credential
  // TODO: Boolean の初期値が null だと可読性が下がるので他の方法を考える
  // user と credential を使って Boolean を返す感じで良さそう
  const [isSignedIn, setIsSignedIn] = useState(null)

  useEffect(() => {
    window.gapi.load('client:auth2', () => {
      window.gapi.client.init(gapiConfig).then(() => {
        const auth = window.gapi.auth2.getAuthInstance()
        const googleUser = auth.currentUser.get()
        setCredential(googleUser.getAuthResponse())
        setIsSignedIn(auth.isSignedIn.get())
      })
    })
  }, [])

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        UserData.get({ uid: firebaseUser.uid }).then(result => {
          setUser(result)
        })
      }
    })
    return unsubscribe
  }, [UserData])

  const signIn = async () => {
    const googleUser = await window.gapi.auth2.getAuthInstance().signIn()
    setCredential(googleUser.getAuthResponse())

    // TODO: 途中でコケたときにロールバック
    const idToken = googleUser.getAuthResponse().id_token
    const result = await firebase
      .auth()
      .signInWithCredential(firebase.auth.GoogleAuthProvider.credential(idToken))

    setIsSignedIn(true)

    const data = result.user.providerData[0]
    await UserData.addProviderData({
      data: {
        uid: data.uid,
        displayName: data.displayName,
        email: data.email,
        photoURL: data.photoURL,
        phoneNumber: data.phoneNumber,
        providerId: data.providerId,
      },
      uid: result.user.uid,
    })
    const userData = await UserData.get({ uid: result.user.uid })
    setUser(userData)

    return userData
  }

  const signOut = () => {
    // TODO: 途中でコケたときの対応
    window.gapi.auth2
      .getAuthInstance()
      .signOut()
      .then(() => firebase.auth().signOut())
      .then(() => {
        setUser(null)
        setCredential(null)
        setIsSignedIn(false)
      })
  }

  const refreshUserData = () => {
    return UserData.get({ uid: user.uid }).then(result => {
      setUser(result)
      return result
    })
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isSignedIn,
        credential,
        signIn,
        signOut,
        refreshUserData,
      }}
      children={children}
    />
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
