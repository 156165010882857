import React from 'react'
import moment from 'moment-timezone'
import 'moment/locale/ja'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import SubjectIcon from '@material-ui/icons/Subject'
import RoomIcon from '@material-ui/icons/Room'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'
import CakeIcon from '@material-ui/icons/Cake'
import WcIcon from '@material-ui/icons/Wc'
import { sexList } from 'utils/config'

import { times } from 'utils/config'

export default function ProfileInfoList({ user }) {
  const introduction =
    user.introduction &&
    user.introduction.split('\n').map((e, index) => {
      return (
        <React.Fragment key={index}>
          {e}
          <br />
        </React.Fragment>
      )
    })

  return (
    <>
      {user.sex && (
        <ListItem style={{ borderBottom: '1px solid #ddd' }}>
          <ListItemIcon>
            <WcIcon />
          </ListItemIcon>
          <ListItemText primary={sexList.find(sex => sex.value === user.sex).label} />
        </ListItem>
      )}
      {user.prefecture && (
        <ListItem style={{ borderBottom: '1px solid #ddd' }}>
          <ListItemIcon>
            <RoomIcon />
          </ListItemIcon>
          <ListItemText primary={user.prefecture} />
        </ListItem>
      )}
      {user.birthday && (
        <ListItem style={{ borderBottom: '1px solid #ddd' }}>
          <ListItemIcon>
            <CakeIcon />
          </ListItemIcon>
          <ListItemText primary={moment().diff(moment(user.birthday.toDate()), 'years') + '歳'} />
        </ListItem>
      )}
      {user.job && (
        <ListItem style={{ borderBottom: '1px solid #ddd' }}>
          <ListItemIcon>
            <WorkOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={user.job} />
        </ListItem>
      )}
      {user.freeTime && (
        <ListItem style={{ borderBottom: '1px solid #ddd' }}>
          <ListItemIcon>
            <EventAvailableIcon />
          </ListItemIcon>
          <ListItemText
            primary={times
              .filter(time => user.freeTime.includes(time.value))
              .map(time => time.label)
              .join(', ')}
          />
        </ListItem>
      )}
      {user.introduction && (
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <SubjectIcon />
          </ListItemIcon>
          <ListItemText primary={introduction} />
        </ListItem>
      )}
    </>
  )
}
