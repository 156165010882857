import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import moment from 'moment-timezone'
import * as yup from 'yup'

import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import FormHelperText from '@material-ui/core/FormHelperText'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import JobIcon from '@material-ui/icons/WorkOutline'
import AreaIcon from '@material-ui/icons/Room'
import SexIcon from '@material-ui/icons/Wc'
import SearchIcon from '@material-ui/icons/Search'
import FreeTimeIcon from '@material-ui/icons/EventAvailable'
import SubjectIcon from '@material-ui/icons/Subject'
import PersonIcon from '@material-ui/icons/Person'
import CakeIcon from '@material-ui/icons/Cake'
import FaceIcon from '@material-ui/icons/Face'
import Checkbox from '@material-ui/core/Checkbox'
import LinearProgress from '@material-ui/core/LinearProgress'

import ImageSelector from 'components/ImageSelector'
import MySnackbar from 'components/MySnackbar'
import { useAuth } from 'hooks/useAuth'
import { prefectures, jobs, sexList, times } from 'utils/config'
import { Timestamp } from 'firestore/common'
import { useFirebaseApp } from 'hooks/useFirebaseApp'
import { useHistory } from 'hooks/useHistory'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1100,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    flex: 1,
  },
  submit: {
    marginRight: theme.spacing(-1),
  },
  textField: {
    width: '100%',
  },
}))

const UserSchema = yup.object().shape({
  photoURL: yup.string().required('必須項目です'),
  name: yup
    .string()
    .required('必須項目です')
    .max(20, '20 文字以内で入力してください')
    .default(''),
  sex: yup
    .string()
    .required('必須項目です')
    .default(''),
  freeTime: yup
    .array()
    .required('必須項目です')
    .default(['weekdayNoon', 'holidayNoon', 'weekdayNight', 'holidayNight']),
  introduction: yup
    .string()
    .max(1000, '1000 文字以内で入力してください')
    .default(''),
  sexFilter: yup.array().default([]),
  birthday: yup.string().default('1990-01-01'),
  prefecture: yup.string().default(''),
  job: yup.string().default(''),
})

export default function ProfileEdit() {
  const { register, handleSubmit, errors, setValue, watch, control } = useForm({
    defaultValues: UserSchema.cast(),
    validationSchema: UserSchema,
  })
  const [submitting, setSubmitting] = useState(false)
  const classes = useStyles()
  const { user, refreshUserData } = useAuth()
  const { freeTime, sexFilter, photoURL } = watch(['freeTime', 'sexFilter', 'photoURL'])
  const { UserData } = useFirebaseApp()
  const history = useHistory()
  const [providerImage, setProviderImage] = useState(null)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  useEffect(() => {
    register({ name: 'photoURL' })
  }, [register])

  useEffect(() => {
    if (user) {
      Object.keys(UserSchema.cast()).forEach(key => {
        if (key === 'birthday' && user.birthday) {
          const value = moment(user.birthday.toDate()).format('YYYY-MM-DD')
          setValue('birthday', value)
        } else {
          user[key] && setValue(key, user[key])
        }
      })
    }
  }, [user, setValue])

  // Firebase Storage に保存されていなかったら providerImage に buffer セット
  useEffect(() => {
    if (user && !user.photoURL.startsWith('https://firebasestorage.googleapis.com')) {
      fetch(user.photoURL)
        .then(res => res.arrayBuffer())
        .then(setProviderImage)
    }
  }, [user])

  const onSubmit = data => {
    setSubmitting(true)

    if (data.prefecture === '') delete data.prefecture
    if (data.job === '') delete data.job
    if (data.birthday === '') {
      delete data.birthday
    } else {
      data.birthday = Timestamp.fromDate(new Date(data.birthday))
    }

    UserData.update({ user: data, uid: user.uid }).then(() => {
      refreshUserData(user.uid).then(() => {
        setSubmitting(false)
        if (history.prev === '/login') {
          history.push('/')
        } else {
          setSnackbarMessage('保存しました')
        }
      })
    })
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {user && user.sex && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography style={{ marginTop: 2, flex: 1 }} variant="body1">
            プロフィール設定
          </Typography>
          <Button
            color="inherit"
            className={classes.submit}
            disabled={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            保存
          </Button>
        </Toolbar>
      </AppBar>
      {submitting && <LinearProgress />}
      <List>
        <ListItem>
          <FaceIcon className={classes.icon} color="action" />
          {user && (
            <ImageSelector
              size={{ width: 200, height: 200 }}
              isAvatar={true}
              path={`users/${user.uid}/profile.jpg`}
              value={photoURL}
              handleChange={value => {
                setValue('photoURL', value)
                if (providerImage) {
                  setProviderImage(null)
                }
              }}
              error={errors.photoURL}
              buffer={providerImage}
            />
          )}
        </ListItem>
        <ListItem>
          <PersonIcon className={classes.icon} color="action" />
          <TextField
            id="name"
            name="name"
            inputRef={register}
            label="名前"
            required
            variant="filled"
            className={classes.textField}
            error={errors.name !== undefined}
            helperText={errors.name && errors.name.message}
          />
        </ListItem>
        <ListItem>
          <SexIcon className={classes.icon} color="action" />
          <FormControl
            required
            variant="filled"
            className={classes.formControl}
            error={errors.sex !== undefined}
          >
            <InputLabel id="sex-select-label">性別</InputLabel>
            <Controller
              as={
                <Select labelId="sex-select-label" id="sex-select">
                  {sexList.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              }
              onChange={([e]) => {
                return e.target.value
              }}
              control={control}
              name="sex"
            />
            {errors.sex && <FormHelperText>{errors.sex.message}</FormHelperText>}
          </FormControl>
        </ListItem>
        <ListItem>
          <FreeTimeIcon className={classes.icon} color="action" />
          <FormControl
            required
            variant="filled"
            className={classes.formControl}
            error={errors.freeTime !== undefined}
          >
            <InputLabel id="free-time-select-label">時間帯</InputLabel>
            <Controller
              as={
                <Select
                  labelId="free-time-select-label"
                  id="free-time-select"
                  multiple
                  renderValue={selected =>
                    times
                      .filter(obj => selected.includes(obj.value))
                      .map(obj => obj.label)
                      .join(', ')
                  }
                >
                  {times.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      <Checkbox color="primary" checked={freeTime.indexOf(value) > -1} />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
              }
              onChange={([e]) => {
                return e.target.value
              }}
              control={control}
              name="freeTime"
            />
            {errors.freeTime && <FormHelperText>{errors.freeTime.message}</FormHelperText>}
          </FormControl>
        </ListItem>
        <ListItem>
          <SearchIcon className={classes.icon} color="action" />
          <FormControl
            variant="filled"
            className={classes.formControl}
            error={errors.sexFilter !== undefined}
          >
            <InputLabel id="sex-filter-select-label">対象</InputLabel>
            <Controller
              as={
                <Select
                  labelId="sex-filter-select-label"
                  id="sex-filter-select"
                  multiple
                  renderValue={selected =>
                    sexList
                      .filter(obj => selected.includes(obj.value))
                      .map(obj => obj.label)
                      .join(', ')
                  }
                >
                  {sexList.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      <Checkbox color="primary" checked={sexFilter.indexOf(value) > -1} />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
              onChange={([e]) => {
                return e.target.value
              }}
              name="sexFilter"
            />
            {errors.sexFilter && <FormHelperText>{errors.sexFilter.message}</FormHelperText>}
          </FormControl>
        </ListItem>
        <ListItem>
          <AreaIcon className={classes.icon} color="action" />
          <FormControl
            variant="filled"
            className={classes.formControl}
            error={errors.prefecture !== undefined}
          >
            <InputLabel id="prefecture-select-label">エリア</InputLabel>
            <Controller
              as={
                <Select labelId="prefecture-select-label" id="prefecture-select">
                  {prefectures.map(value => {
                    return (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    )
                  })}
                </Select>
              }
              control={control}
              onChange={([e]) => {
                return e.target.value
              }}
              name="prefecture"
            />
            {errors.prefecture && <FormHelperText>{errors.prefecture.message}</FormHelperText>}
          </FormControl>
        </ListItem>
        <ListItem>
          <JobIcon className={classes.icon} color="action" />
          <FormControl
            variant="filled"
            className={classes.formControl}
            error={errors.job !== undefined}
          >
            <InputLabel id="job-select-label">職業</InputLabel>
            <Controller
              as={
                <Select labelId="job-select-label" id="job-select">
                  {jobs.map(job => (
                    <MenuItem key={job} value={job}>
                      {job}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
              onChange={([e]) => {
                return e.target.value
              }}
              name="job"
            />
            {errors.job && <FormHelperText>{errors.job.message}</FormHelperText>}
          </FormControl>
        </ListItem>
        <ListItem>
          <CakeIcon className={classes.icon} color="action" />
          <TextField
            id="birthday"
            name="birthday"
            inputRef={register}
            type="date"
            label="誕生日"
            variant="filled"
            className={classes.textField}
            error={errors.birthday !== undefined}
            helperText={errors.birthday && errors.birthday.message}
          />
        </ListItem>
        <ListItem>
          <SubjectIcon className={classes.icon} color="action" />
          <TextField
            id="introduction"
            name="introduction"
            inputRef={register}
            label="自己紹介"
            className={classes.textField}
            variant="filled"
            multiline
            rowsMax="10"
            error={errors.introduction !== undefined}
            helperText={errors.introduction && errors.introduction.message}
          />
        </ListItem>
        <ListItem style={{ flexDirection: 'row-reverse' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            保存
          </Button>
        </ListItem>
      </List>
      <MySnackbar message={snackbarMessage} onClose={() => setSnackbarMessage(null)} />
    </div>
  )
}
