import React, { useMemo, useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment-timezone'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Send from '@material-ui/icons/Send'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'

import { useAuth } from 'hooks/useAuth'
import { useFirebaseApp } from 'hooks/useFirebaseApp'
import { useHistory } from 'hooks/useHistory'
import Profile from 'components/Profile'
import MessageListItem from 'components/MessageListItem'
import CustomModal from 'components/CustomModal'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '0.5px solid #aaa',
  },
  textField: {
    flex: 1,
    margin: theme.spacing(1),
    '& .MuiOutlinedInput-multiline': {
      padding: theme.spacing(1),
    },
  },
}))

export default function Chat() {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const { user } = useAuth()
  const [open, setOpen] = useState(true)
  const [activeUser, setActiveUser] = useState(null)
  const [messages, setMessages] = useState(null)
  const [event, setEvent] = useState(null)
  const [messageText, setMessageText] = useState('')
  const messagesBottom = useRef(null)
  const { db, EventData } = useFirebaseApp()

  useEffect(() => {
    if (event && user) {
      const eventRef = db.collection('events').doc(event.id)
      const unsubscribe = eventRef
        .collection('messages')
        .orderBy('createdAt')
        .onSnapshot(
          ({ docs }) => {
            const arr = docs.map(doc => ({ ...doc.data(), id: doc.id }))

            if (arr.length > 0 && arr[arr.length - 1].creator !== user.uid) {
              let data = {}
              data[`members.${user.uid}.lastViewAt`] = arr[arr.length - 1].createdAt
              eventRef.update(data)
            }

            setMessages(arr)
          },
          error => {
            console.error(error)
          }
        )
      return unsubscribe
    }
  }, [event, user, db])

  useEffect(() => {
    EventData.get({ id }).then(event => setEvent(event))
  }, [id, EventData])

  useEffect(() => {
    if (messages && messagesBottom.current) {
      messagesBottom.current.scrollIntoView({ behavior: 'instant' })
    }
  }, [messages, messagesBottom])

  const handleSend = () => {
    setMessageText('')
    EventData.sendMessage({
      data: {
        text: messageText,
        creator: user.uid,
      },
      id: event.id,
    })
  }

  const list = useMemo(() => {
    const handleClick = uid => {
      if (user.uid !== uid) {
        setActiveUser(user)
      }
    }

    // メッセージ読み込み中
    if (!messages) return null

    // メッセージがまだ投稿されていない場合
    if (messages.length === 0) {
      return (
        <MessageListItem
          message={{
            text: 'マッチング成立おめでとうございます！早速メッセージを送ってみましょう！',
            createdAt: moment(),
          }}
          user={{ photoURL: '/logo192.png' }}
          isMine={false}
          handleClick={() => {}}
        />
      )
    }

    return messages.map(message => {
      const isMine = message.creator === user.uid
      return (
        <MessageListItem
          key={message.id}
          message={message}
          user={Object.values(event.members).find(member => member.uid === message.creator)}
          isMine={isMine}
          handleClick={handleClick}
        />
      )
    })
  }, [messages, user, event])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={() => history.goBack()}
      fullScreen
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'left' }}
    >
      <DialogTitle style={{ padding: 0 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={() => setOpen(false)}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="body1">{event && event.summary}</Typography>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <List>
          {list}
          <div ref={messagesBottom}></div>
        </List>
        <div className={classes.input}>
          <TextField
            className={classes.textField}
            multiline
            variant="outlined"
            rowsMax="3"
            value={messageText}
            onChange={e => setMessageText(e.target.value)}
          />
          <IconButton
            edge="start"
            color="primary"
            aria-label="send"
            disabled={messageText.length === 0}
            onClick={handleSend}
          >
            <Send />
          </IconButton>
        </div>
      </DialogContent>
      <CustomModal open={activeUser !== null} handleClose={() => setActiveUser(null)}>
        <Profile user={activeUser} buttons={[]} />
      </CustomModal>
    </Dialog>
  )
}
