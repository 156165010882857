import React, { useState } from 'react'

import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

import { useFirebaseApp } from 'hooks/useFirebaseApp'
import { FieldValue } from 'firestore/common'
import { useAuth } from 'hooks/useAuth'
import { useHistory } from 'hooks/useHistory.js'

export default function RecommendListItem({ recommend }) {
  const [item, setItem] = useState(recommend)
  const { RecommendData } = useFirebaseApp()
  const { user } = useAuth()
  const history = useHistory()

  const handleClickLike = e => {
    if (!user) return
    const data = {
      likes: item.likes.includes(user.uid)
        ? FieldValue.arrayRemove(user.uid)
        : FieldValue.arrayUnion(user.uid),
    }
    RecommendData.update({ id: item.id, data }).then(setItem)

    // 遷移イベントを発火しない
    e.stopPropagation()
  }

  return (
    <div
      style={{ padding: '4px 8px', borderBottom: '1px solid #ddd' }}
      button="true"
      onClick={() => {
        const type = 'priority' in item ? 'recommend' : 'event'
        const state = {}
        state[type] = item
        history.push({
          pathname: `/${type}s/${item.id}`,
          state,
        })
      }}
    >
      <div style={{ position: 'relative' }}>
        <img
          style={{ width: '100%', height: 200, objectFit: 'cover' }}
          src={item.imageURL || '/event.png'}
          alt={item.summary}
          onError={event => (event.target.src = '/event.png')}
        />
        <div
          style={{
            position: 'absolute',
            right: 0,
            left: 0,
            bottom: 4,
            height: 48,
            background: 'rgba(0,0,0,0.4)',
            color: '#fff',
            padding: 8,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Typography
            style={{
              flex: 1,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            component="p"
          >
            {item.summary}
          </Typography>
          <div style={{ minWidth: 40 }}>
            {item.likes && (
              <>
                <IconButton onClick={handleClickLike} edge="end">
                  {user && item.likes.includes(user.uid) ? (
                    <FavoriteIcon style={{ fontSize: 16, color: '#fff' }} />
                  ) : (
                    <FavoriteBorderIcon style={{ fontSize: 16, color: '#fff' }} />
                  )}
                </IconButton>
                <Typography style={{ fontSize: 12, marginLeft: 2, color: '#fff' }} component="span">
                  {Math.min(item.likes.length, 999)}
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
