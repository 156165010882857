import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useFirebaseApp } from 'hooks/useFirebaseApp'
import { useAuth } from 'hooks/useAuth'
import { useHistory } from 'hooks/useHistory'
import UserList from 'components/UserList'

export default function LikeList() {
  const location = useLocation()
  const history = useHistory()
  const [users, setUsers] = useState([])
  const recommend = location.state
  const { UserData } = useFirebaseApp()
  const { user } = useAuth()

  useEffect(() => {
    if (user && recommend && recommend.likes && recommend.likes.length > 0) {
      const likes = recommend.likes.slice()
      const uids = likes
        .filter(uid => uid !== user.uid)
        .reverse()
        .slice(0, 9)
      if (uids.length > 0) {
        UserData.list({ uids }).then(users =>
          setUsers([{ label: '', values: users, disabled: false }])
        )
      }
    }
  }, [UserData, recommend, user])

  const onSubmit = selected => {
    let attendees = {}
    users[0].values
      .filter(user => selected.includes(user.uid))
      .forEach(user => {
        attendees[user.uid] = {
          uid: user.uid,
          name: user.name,
          photoURL: user.photoURL,
        }
      })

    history.push({
      pathname: '/events/new',
      state: {
        recommend,
        attendees,
      },
    })
  }

  return (
    <UserList
      users={users}
      title="興味あり"
      handleSubmit={onSubmit}
      submitText="選択したユーザーを誘う"
      canSubmit={true}
    />
  )
}
