import React from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Checkbox from '@material-ui/core/Checkbox'

export default function UserListItem({ user, checked, disabled, handleClick, handleChange }) {
  return (
    <ListItem
      key={user.uid}
      onClick={!disabled ? handleClick : () => {}}
      style={{ borderBottom: '1px solid #ddd' }}
      button={!disabled}
    >
      <ListItemAvatar>
        <Avatar alt={user.name} src={user.photoURL} />
      </ListItemAvatar>
      <ListItemText style={{ flex: 1 }} primary={user.name} />
      {!disabled && (
        <ListItemSecondaryAction>
          <Checkbox color="primary" edge="end" onChange={handleChange} checked={checked} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}
