import React, { useMemo } from 'react'
import moment from 'moment-timezone'

import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

import ListItemImage from 'components/ListItemImage'

const useStyles = makeStyles(theme => ({
  primaryText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
  },
  state: {
    marginTop: theme.spacing(1),
    padding: '2px 6px',
    textAlign: 'right',
    fontSize: 10,
    color: theme.palette.primary.main,
    border: '1px solid ' + theme.palette.primary.main,
    borderRadius: 3,
  },
  declined: {
    background: '#eee',
  },
}))

export default function EventListItem({ event, user, handleClick }) {
  const classes = useStyles()
  const date =
    moment(event.start.toDate()).format('MM/DD HH:mm') +
    '-' +
    moment(event.end.toDate()).format('HH:mm')

  const state = useMemo(() => {
    switch (event.privacyStatus) {
      case 'private':
        break
      case 'unlisted':
        break
      case 'public':
        break
      default:
        return
    }

    // イベント作成者
    if (event.creator.uid === user.uid) {
      // 非公開
      if (event.privacyStatus === 'private') {
        switch (event.state) {
          case 'created':
            return '相手選択待ち'
          case 'invited':
            return '募集リクエスト中'
          case 'declined':
            return '不成立'
          case 'matched':
            return 'マッチ済'
          default:
            return null
        }
      }

      // 限定公開 or 公開

      // 定員に達した
      if (event.memberIds.length === event.limit) {
        return '受付終了'
      }

      // 公開中
      return event.privacyStatus === 'public' ? '全体に公開中' : 'URL で公開中'

      // イベント参加者
    } else {
      if (event.privacyStatus === 'private') {
        switch (event.state) {
          case 'invited':
            return 'リクエスト受付中'
          case 'matched':
            return 'マッチ済'
          default:
            return null
        }
      }

      // 限定公開 or 公開

      return event.memberIds.includes(user.uid) ? '承認済' : '承認待ち'
    }
  }, [event, user.uid])

  return (
    <ListItem
      id={event.id}
      onClick={() => handleClick(event)}
      alignItems="flex-start"
      style={{ borderBottom: '1px solid #ddd' }}
      className={event.state === ' declined' ? classes.declined : ''}
      button
    >
      {event.creator.uid === user.uid ? (
        <ListItemImage src={event.imageURL} alt={event.summary} />
      ) : (
        <ListItemAvatar>
          <Avatar alt={event.creator.name} src={event.creator.photoURL} />
        </ListItemAvatar>
      )}
      <ListItemText
        style={{ flex: 1 }}
        className={classes.textArea}
        primary={<Typography className={classes.primaryText}>{event.summary}</Typography>}
        secondary={
          <Typography component="span" variant="caption" color="textPrimary">
            {date}
          </Typography>
        }
      />
      <Typography component="span" variant="caption" className={classes.state}>
        {state}
      </Typography>
    </ListItem>
  )
}
