import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: theme.spacing(2),
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  iconButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    background: 'rgb(0,0,0,0.3)',
  },
  icon: {
    color: 'rgb(255,255,255,0.7)',
  },
}))

export default function Popup({ open, handleClose, children }) {
  const classes = useStyles()
  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          {React.cloneElement(children, { handleClose })}
          <IconButton size="small" className={classes.iconButton} onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        </Paper>
      </Fade>
    </Modal>
  )
}
