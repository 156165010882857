import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Switch from '@material-ui/core/Switch'

import { useFirebaseApp } from 'hooks/useFirebaseApp'
import { useAuth } from 'hooks/useAuth'
import { notifications } from 'utils/config'
import { useHistory } from 'hooks/useHistory'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1100,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
}))

export default function Settings() {
  const classes = useStyles()
  const { user } = useAuth()
  const { UserData } = useFirebaseApp()
  const history = useHistory()
  const [checked, setChecked] = React.useState(null)

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
    UserData.updateSettings({ data: { notifications: newChecked }, uid: user.uid })
  }

  useEffect(() => {
    if (!user || !user.uid) return
    UserData.getSettings({ uid: user.uid }).then(data => setChecked(data.notifications))
  }, [UserData, user])

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="body1">
            各種設定
          </Typography>
        </Toolbar>
      </AppBar>
      {checked && (
        <List subheader={<ListSubheader>通知</ListSubheader>}>
          {notifications.map(({ label, value }) => {
            return (
              <ListItem key={value}>
                <ListItemText id={value} primary={label} />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    color="primary"
                    onChange={handleToggle(value)}
                    checked={checked.indexOf(value) !== -1}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      )}
    </div>
  )
}
