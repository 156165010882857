import React, { useRef, useState, useEffect } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useRecommend } from 'hooks/useRecommend'
import RecommendListItem from 'components/RecommendListItem'
import LargeRecommendListItem from 'components/LargeRecommendListItem'
import CenteredRoot from 'components/CenteredRoot'

export default function RecommendList({ category }) {
  const { data, load, loadMore, loading } = useRecommend()
  const ref = useRef(null)
  const [scrollHeight, setScrollHeight] = useState(0)

  useEffect(() => {
    if (!data || !(category in data)) {
      load(category)
    }
  }, [load, category, data])

  // これをしないとさらに読み込んだ時にスクロール位置が最下部まで移動する
  useEffect(() => {
    if (ref.current && !loading && scrollHeight !== 0) {
      ref.current.scrollTo(0, scrollHeight)
      setScrollHeight(0)
    }
  }, [loading, ref, scrollHeight])

  if (!data || !(category in data)) {
    return (
      <CenteredRoot>
        <CircularProgress />
      </CenteredRoot>
    )
  }
  const { items, hasMore } = data[category]

  return (
    <List
      style={{
        background: '#fff',
        paddingTop: 4,
        paddingBottom: 4,
        height: window.innerHeight - 170,
      }}
      ref={ref}
    >
      {(items || [])
        .map(doc => ({ ...doc.data(), id: doc.id }))
        .map((e, i) =>
          i === 0 ? (
            <LargeRecommendListItem key={e.id} recommend={e} />
          ) : (
            <RecommendListItem key={e.id} recommend={e} />
          )
        )}
      {hasMore && (
        <ListItem
          button
          disabled={loading}
          onClick={() => {
            setScrollHeight(ref.current.scrollTop)
            loadMore(category)
          }}
        >
          <ListItemText style={{ textAlign: 'center' }} primary="さらに読み込む" />
        </ListItem>
      )}
    </List>
  )
}
