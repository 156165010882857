import React from 'react'
import { Route } from 'react-router-dom'

import List from '@material-ui/core/List'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import { useAuth } from 'hooks/useAuth'
import { useEvent } from 'hooks/useEvent'
import { useHistory } from 'hooks/useHistory'
import ChatListItem from 'components/ChatListItem'
import Chat from 'pages/Chat'
import CenteredRoot from 'components/CenteredRoot'
import NotLogin from 'components/NotLogin'

export default function ChatList() {
  const { user, isSignedIn } = useAuth()
  const history = useHistory()
  const { messages } = useEvent()

  const handleClick = e => {
    const eventId = e.currentTarget.id
    history.push({
      pathname: `/messages/${eventId}`,
      state: messages.find(e => e.id === eventId),
    })
  }

  if (isSignedIn === false) {
    return <NotLogin />
  }

  if (messages === null) {
    return (
      <CenteredRoot>
        <CircularProgress />
      </CenteredRoot>
    )
  }

  if (messages.length === 0) {
    return (
      <CenteredRoot>
        <Typography align="center" variant="body2">
          マッチングが成立すると
          <br />
          ここからチャットができます
        </Typography>
      </CenteredRoot>
    )
  }

  return (
    <>
      <List>
        {messages.map(event => {
          return (
            <ChatListItem key={event.id} event={event} uid={user.uid} handleClick={handleClick} />
          )
        })}
      </List>
      <Route path="/messages/:id" component={Chat} />
    </>
  )
}
