import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      paddingTop: 0,
    },
  },
  iconButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    background: 'rgb(0,0,0,0.3)',
  },
  icon: {
    color: 'rgb(255,255,255,0.7)',
  },
}))

export default function CustomModal({ open, handleClose, children }) {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'up' }}
    >
      <DialogContent className={classes.content}>
        {children}
        <IconButton size="small" className={classes.iconButton} onClick={handleClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      </DialogContent>
    </Dialog>
  )
}
