import React, { useMemo, useState, useEffect, useContext, createContext } from 'react'
import { useAuth } from 'hooks/useAuth'
import { useRemoteConfig } from 'hooks/useRemoteConfig'
import { useFirebaseApp } from 'hooks/useFirebaseApp'
import moment from 'moment-timezone'

const EventContext = createContext()

export function EventProvider({ children }) {
  const [data, setData] = useState(null)
  const [canCreateEvent, setCanCreateEvent] = useState(false)
  const { eventCreateLimit } = useRemoteConfig()
  const { user } = useAuth()
  const { db } = useFirebaseApp()

  useEffect(() => {
    if (!user || !user.uid) return
    const unsubscribe = db
      .collection('events')
      .where('attendeeIds', 'array-contains', user.uid)
      .orderBy('createdAt', 'desc')
      .onSnapshot(
        ({ docs }) => {
          setData(docs.map(doc => ({ ...doc.data(), id: doc.id })))
        },
        error => {
          console.error(error)
        }
      )
    return unsubscribe
  }, [user, db])

  useEffect(() => {
    if (!data) return
    const startOfDay = moment().startOf('day').valueOf()
    const todayEvents = data.filter(e => e.createdAt && e.createdAt.seconds * 1000 > startOfDay)
    setCanCreateEvent(todayEvents.length < eventCreateLimit)
  }, [data, eventCreateLimit])

  const events = useMemo(() => {
    if (!data || !user) return null
    return data.filter(
      event => moment(event.end.toDate()).isAfter(moment()) && event.creator.uid === user.uid
    )
  }, [user, data])

  const invitations = useMemo(() => {
    if (!data || !user) return null
    return data.filter(
      event =>
        moment(event.end.toDate()).isAfter(moment()) &&
        event.creator.uid !== user.uid &&
        event.attendeeIds.includes(user.uid) &&
        event.attendees[user.uid].accept !== false
    )
  }, [user, data])

  const messages = useMemo(() => {
    if (!data || !user) return null
    return data.filter(event => event.state === 'matched' && event.memberIds.includes(user.uid))
  }, [user, data])

  return (
    <EventContext.Provider
      value={{ events, invitations, messages, canCreateEvent }}
      children={children}
    />
  )
}

export function useEvent() {
  return useContext(EventContext)
}
