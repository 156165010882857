import React, { useEffect, useMemo } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import Login from 'pages/Login'
import Logout from 'pages/Logout'
import ChatList from 'pages/ChatList'
import User from 'pages/User'
import Top from 'pages/Top'
import Settings from 'pages/Settings'
import Error from 'pages/Error'
import EventList from 'pages/EventList'
import ProfileEdit from 'pages/ProfileEdit'
import Recommend from 'pages/Recommend'
import EventNew from 'pages/EventNew'
import NavBar from 'components/NavBar'
import TabBar from 'components/TabBar'
import { EventProvider } from 'hooks/useEvent'
import { RecommendProvider } from 'hooks/useRecommend'
import { RemoteConfigProvider } from 'hooks/useRemoteConfig'
import { useAuth } from 'hooks/useAuth'
import { useFirebaseApp } from 'hooks/useFirebaseApp'

import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import orange from '@material-ui/core/colors/orange'
import lightBlue from '@material-ui/core/colors/lightBlue'
import { useHistory } from 'hooks/useHistory.js'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
  },
}))

const theme = createMuiTheme({
  palette: {
    primary: {
      ...orange,
      main: '#fb8c00',
      light: '#ffb74d',
      dark: '#e65100',
      contrastText: '#fff',
    },
    secondary: lightBlue,
  },
  overrides: {
    MuiList: {
      root: {
        flex: 1,
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative',
      },
    },
  },
})

export default function App() {
  const classes = useStyles()
  const history = useHistory()
  const { pathname } = useLocation()
  const { user, credential, isSignedIn, refreshUserData } = useAuth()
  const { UserData, functions } = useFirebaseApp()

  const isPublicPath = useMemo(() => {
    const privatePaths = ['/events/new']
    const publicPaths = [
      '/',
      '/login',
      '/401',
      '/recommends',
      '/events',
      '/messages',
      /\/recommends\/[A-Za-z0-9]*$/,
      /\/events\/[A-Za-z0-9]*$/,
      /\/events\/[A-Za-z0-9]*\/members/,
    ]

    const match = pattern => {
      if (typeof pattern === 'string') {
        return pathname === pattern
      }
      if (typeof pattern === 'object') {
        return pathname.match(pattern) !== null
      }
      return false
    }

    // publicPaths にパターンマッチする privatePaths を除外する
    if (privatePaths.some(match)) {
      return false
    }
    return publicPaths.some(match)
  }, [pathname])

  useEffect(() => {
    if (!isPublicPath && isSignedIn === false) {
      history.push(`/login?continue=${pathname}`)
    }
  }, [isSignedIn, history, isPublicPath, pathname])

  useEffect(() => {
    if (!user || !credential) return
    UserData.updateLastLoginAt({ uid: user.uid })
    functions
      .httpsCallable('fetchGoogleCalendar')({ credential, uid: user.uid })
      .then(res => {
        if (user.busy.length === res.data.length) return
        refreshUserData()
      })
      .catch(error => {
        // busy が取れなくてもクリティカルではないのでそのままレンダリングする
        console.error(error)
      })
  }, [user, credential, refreshUserData, UserData, functions])

  return (
    <RemoteConfigProvider>
      <EventProvider>
        <RecommendProvider>
          <ThemeProvider theme={theme}>
            <div className={classes.root}>
              <Switch>
                <Route path="/recommends/:id" component={Recommend} />
                <Route>
                  <NavBar />
                  <Switch>
                    <Route path="/events/new" component={EventNew} />
                    <Route path="/events" component={EventList} />
                    <Route path="/login" component={Login} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/profile/edit" component={ProfileEdit} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/messages" component={ChatList} />
                    <Route path="/recommends" component={Top} />
                    <Route path="/users/:id" component={User} />
                    <Route path="/401" component={Error} />
                    <Redirect to="/recommends" />
                  </Switch>
                  <TabBar />
                </Route>
              </Switch>
            </div>
          </ThemeProvider>
        </RecommendProvider>
      </EventProvider>
    </RemoteConfigProvider>
  )
}
