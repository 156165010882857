import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { useAuth } from 'hooks/useAuth'
import { useQueryString } from 'hooks/useQueryString'
import { useHistory } from 'hooks/useHistory'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1100,
    background: theme.palette.primary.main,
    textAlign: 'center',
  },
  content: {
    position: 'absolute',
    top: '25%',
    left: theme.spacing(3),
    right: theme.spacing(3),
    bottom: '25%',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  appName: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 54,
    marginBottom: theme.spacing(2),
    flex: 1,
  },
  links: {
    position: 'absolute',
    bottom: theme.spacing(2),
    fontSize: 10,
    textAlign: 'center',
    width: '100%',
    '& a': {
      margin: theme.spacing(0, 1),
      color: '#fff',
    },
  },
  button: {
    color: '#fff',
    borderColor: '#fff',
  },
}))

export default function Login() {
  const classes = useStyles()
  const history = useHistory()
  const { search } = useLocation()
  const qs = useQueryString()
  const { signIn, isSignedIn } = useAuth()

  useEffect(() => {
    if (isSignedIn) {
      history.push('/')
    }
  })

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography className={classes.appName} align="center" variant="h2">
          LACHSY
        </Typography>
        <Button
          id="login"
          className={classes.button}
          onClick={() =>
            signIn().then(user => {
              if (!user.sex) {
                history.push('/profile/edit' + search)
                return
              }
              history.push(qs.continue || '/recommends')
            })
          }
          variant="outlined"
          size="large"
        >
          GOOGLE でログイン
        </Button>
      </div>
      <div className={classes.links}>
        <a href="/terms.html">利用規約</a>
        <a href="/privacy.html">プライバシーポリシー</a>
      </div>
    </div>
  )
}
