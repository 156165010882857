import React, { useState, useMemo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import { useAuth } from 'hooks/useAuth'
import { useHistory } from 'hooks/useHistory'

const useStyles = makeStyles(theme => ({
  appName: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
  },
  grow: {
    flexGrow: 1,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}))

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const { user, isSignedIn, signIn, signOut } = useAuth()
  const history = useHistory()

  const handleClickSettings = () => {
    setIsMenuOpen(false)
    history.push('/settings')
  }

  const handleClickMyPage = () => {
    setIsMenuOpen(false)
    history.push(`/users/${user.uid}`)
  }

  const handleClickProfileEdit = () => {
    setIsMenuOpen(false)
    history.push('/profile/edit')
  }

  const handleClickSignOut = () => {
    setIsMenuOpen(false)
    signOut()
  }

  const handleCloseMenu = () => {
    setIsMenuOpen(false)
  }

  const button = useMemo(() => {
    if (isSignedIn === null) return null
    if (isSignedIn === false)
      return (
        <Button style={{ color: '#fff' }} onClick={signIn}>
          ログイン
        </Button>
      )
    if (!user) return null
    return (
      <IconButton
        edge="end"
        onClick={e => {
          setAnchorEl(e.currentTarget)
          setIsMenuOpen(!isMenuOpen)
        }}
        color="inherit"
      >
        <Avatar className={classes.avatar} src={user.photoURL} alt={user.name} />
      </IconButton>
    )
  }, [isSignedIn, user, signIn, classes, isMenuOpen])

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography
            className={classes.appName}
            variant="h6"
            button="true"
            onClick={() => history.push('/')}
          >
            LACHSY
          </Typography>
          <div className={classes.grow} />
          {button}
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleClickMyPage}>マイページ</MenuItem>
        <MenuItem onClick={handleClickProfileEdit}>プロフィール編集</MenuItem>
        <MenuItem onClick={handleClickSettings}>各種設定</MenuItem>
        <MenuItem onClick={handleClickSignOut}>ログアウト</MenuItem>
      </Menu>
    </>
  )
}
