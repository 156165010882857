import { FieldValue } from 'firestore/common'
import { FIRESTORE } from 'utils/config'

export const add = ({ db, data, id }) => {
  return db
    .collection('events')
    .doc(id)
    .set({ ...data, createdAt: FieldValue.serverTimestamp() })
}

export const get = ({ db, id }) => {
  return db
    .collection('events')
    .doc(id)
    .get()
    .then(doc => ({ ...doc.data(), id: doc.id }))
}

export const list = ({ db, queries, startAfter }) => {
  let ref = db.collection('events')

  if (queries) {
    queries.forEach(q => {
      ref = ref.where(q.field, q.operator, q.value)
    })
  }

  ref = ref
    .where('privacyStatus', '==', 'public')
    .orderBy('createdAt', 'desc')
    .limit(FIRESTORE.LIMIT)

  if (startAfter) {
    ref = ref.startAfter(startAfter)
  }

  return ref.get().then(({ docs }) => docs)
}

export const generateId = ({ db }) => {
  return db.collection('events').doc().id
}

export const update = ({ db, data, id }) => {
  return db
    .collection('events')
    .doc(id)
    .update(data)
    .then(() => get({ db, id }))
}

export const sendMessage = async ({ db, data, id }) => {
  const message = { ...data, createdAt: FieldValue.serverTimestamp() }
  const eventRef = db.collection('events').doc(id)

  await eventRef.collection('messages').add(message)
  let event = {
    lastMessage: message,
  }
  event[`members.${message.creator}.lastViewAt`] = message.createdAt
  const result = await eventRef.update(event)
  return result
}
