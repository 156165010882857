import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { Helmet } from 'react-helmet'
import Markdown from 'react-markdown'
import { Switch, Route, useParams } from 'react-router-dom'

import moment from 'moment-timezone'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ScheduleIcon from '@material-ui/icons/Schedule'
import LocalActivityIcon from '@material-ui/icons/LocalActivity'
import SubjectIcon from '@material-ui/icons/Subject'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Avatar from '@material-ui/core/Avatar'
import AttachmentIcon from '@material-ui/icons/Attachment'
import PublicIcon from '@material-ui/icons/Public'
import AssignmentIcon from '@material-ui/icons/Assignment'
import TextField from '@material-ui/core/TextField'
import EditIcon from '@material-ui/icons/Edit'
import GroupIcon from '@material-ui/icons/Group'
import LabelIcon from '@material-ui/icons/Label'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { useAuth } from 'hooks/useAuth'
import ListItemCard from 'components/ListItemCard'
import { FieldValue } from 'firestore/common'
import { postEvent } from 'utils/ApiRequest'
import { useFirebaseApp } from 'hooks/useFirebaseApp'
import Profile from 'components/Profile'
import Recommend from 'components/Recommend'
import Popup from 'components/Popup'
import CustomModal from 'components/CustomModal'
import EventMemberList from 'pages/EventMemberList'
import MySnackbar from 'components/MySnackbar'
import { useHistory } from 'hooks/useHistory'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      paddingTop: 0,
    },
  },
  list: {
    paddingBottom: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  listItem: {
    background: '#fff',
    borderBottom: '1px solid #ddd',
  },
  onelineText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  submit: {
    padding: theme.spacing(2, 2, 2, 2),
    borderTop: '1px solid #ddd',
    background: '#f8f8f8',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  button: {
    minWidth: 100,
  },
  tag: {
    display: 'inline-block',
    background: '#888',
    color: '#fff',
    padding: theme.spacing(0.5, 1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  listItemBadge: {
    minWidth: theme.spacing(6),
    textAlign: 'center',
  },
  badge: {
    fontSize: 11,
    fontWeight: 'bold',
    color: '#fff',
    background: theme.palette.primary.main,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(2),
  },
}))

export default function Event() {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [activeUser, setActiveUser] = useState(null)
  const [event, setEvent] = useState(null)
  const [recommend, setRecommend] = useState(null)
  const [recommendModalOpen, setRecommendModalOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)
  const history = useHistory()
  const eventUrlRef = useRef(null)
  const { id } = useParams()
  const { user, isSignedIn, signIn } = useAuth()
  const { EventData, RecommendData } = useFirebaseApp()

  useEffect(() => {
    if (id) {
      EventData.get({ id }).then(setEvent)
    }
  }, [id, EventData])

  useEffect(() => {
    if (event && event.recommendId) {
      RecommendData.get({ id: event.recommendId }).then(setRecommend)
    }
  }, [event, RecommendData])

  const attendees = useMemo(() => {
    if (!event) return []
    if (event.privacyStatus === 'private' && event.state === 'matched') {
      return Object.values(event.members)
    }
    return Object.values(event.attendees).slice(0, 5)
  }, [event])

  const date = useMemo(() => {
    if (!event) return null
    return (
      moment(event.start.toDate()).format('YYYY/MM/DD HH:mm') +
      ' - ' +
      moment(event.end.toDate()).format('HH:mm')
    )
  }, [event])

  const copyEventUrl = useCallback(() => {
    eventUrlRef.current.select()
    const suceeded = document.execCommand('copy')
    setSnackbarMessage(suceeded ? 'コピーしました' : 'コピーに失敗しました')
  }, [eventUrlRef])

  const tags = useMemo(() => {
    if (!event) return []
    return event.tags.map(tag => {
      return (
        <Typography key={tag} variant="caption" className={classes.tag}>
          {tag}
        </Typography>
      )
    })
  }, [event, classes])

  const buttons = useMemo(() => {
    const handleAccept = async () => {
      setSubmitting(true)
      let data = {
        memberIds: [],
        members: {},
        state: 'matched',
      }

      const members = [event.creator, user]
      members.forEach(user => {
        data.memberIds.push(user.uid)
        data.members[user.uid] = {
          uid: user.uid,
          name: user.name,
          photoURL: user.photoURL,
          lastViewAt: FieldValue.serverTimestamp(),
        }
      })
      data[`attendees.${user.uid}.accept`] = true
      await EventData.update({ data, id: event.id })

      if (event.recommendId) {
        for (const member of members) {
          await RecommendData.addParticipant({ uid: member.uid, id: event.recommendId })
        }
      }

      if (process.env.NODE_ENV === 'production') {
        const zone = 'Asia/Tokyo'
        await postEvent({
          summary: event.summary,
          start: {
            dateTime: moment.tz(event.start.toDate(), zone).format(),
            timeZone: zone,
          },
          end: {
            dateTime: moment.tz(event.end.toDate(), zone).format(),
            timeZone: zone,
          },
          description: event.description,
        }).catch(error => {
          // ログイン時に権限を拒否ししていた場合にエラー発生する
          // 特にエラー対応をしない
          console.error(error)
        })
      }
      await EventData.get({ id }).then(setEvent)
      setSubmitting(false)
    }

    const handleDecline = async () => {
      setSubmitting(true)
      let data = {}
      data[`attendees.${user.uid}.accept`] = false
      const attendees = Object.values(event.attendees)
      const declineCount = attendees.filter(attendee => {
        return attendee.accept === false
      }).length

      // 自分が辞退すると attendees 全てが辞退となる場合
      if (declineCount + 1 === attendees.length - 1) {
        data.state = 'declined'
      }

      await EventData.update({ data, id: event.id })
      setSubmitting(false)
      history.push(`/events`)
    }

    if (!event) return null

    // マッチング済み
    if (user && event.state === 'matched' && event.memberIds.includes(user.uid)) {
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => history.push(`/messages/${event.id}`)}
        >
          チャットする
        </Button>
      )
    }

    switch (event.privacyStatus) {
      case 'public':
      case 'unlisted':
        // 未ログイン
        if (isSignedIn === false) {
          return (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={submitting}
              onClick={signIn}
            >
              ログインして参加する
            </Button>
          )
        }

        // 読込中
        if (!user) return null

        // 作成者
        if (event.creator.uid === user.uid) {
          return (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={submitting}
              onClick={copyEventUrl}
            >
              イベント URL をコピー
            </Button>
          )
        }

        // 参加表明済み
        if (event.attendeeIds.includes(user.uid)) return null

        // 定員に達していた場合
        if (event.memberIds.length >= event.limit) return null

        // 閲覧者
        return (
          <Button
            variant="contained"
            color="primary"
            disabled={submitting}
            className={classes.button}
            onClick={async () => {
              setSubmitting(true)
              let data = {
                attendeeIds: FieldValue.arrayUnion(user.uid),
              }
              data[`attendees.${user.uid}`] = {
                uid: user.uid,
                name: user.name,
                photoURL: user.photoURL,
              }
              const response = await EventData.update({ data, id: event.id })
              if (process.env.NODE_ENV === 'production') {
                const zone = 'Asia/Tokyo'
                await postEvent({
                  summary: event.summary,
                  start: {
                    dateTime: moment.tz(event.start.toDate(), zone).format(),
                    timeZone: zone,
                  },
                  end: {
                    dateTime: moment.tz(event.end.toDate(), zone).format(),
                    timeZone: zone,
                  },
                  description: event.description,
                }).catch(error => {
                  // ログイン時に権限を拒否ししていた場合にエラー発生する
                  // 特にエラー対応をしない
                  console.error(error)
                })
              }
              setEvent(response)
              setSubmitting(false)
            }}
          >
            参加したい
          </Button>
        )

      case 'private':
        // 読込中
        if (!user) return null

        // 非公開イベントの招待されたユーザー
        if (event.creator.uid !== user.uid && event.attendeeIds.includes(user.uid)) {
          return (
            <>
              <Button
                style={{ marginLeft: 8 }}
                variant="contained"
                color="primary"
                disabled={submitting}
                className={classes.button}
                onClick={handleAccept}
              >
                承諾する
              </Button>
              <Button
                variant="contained"
                color="default"
                disabled={submitting}
                className={classes.button}
                onClick={handleDecline}
              >
                断る
              </Button>
            </>
          )
        }
        return null

      default:
        return null
    }
  }, [
    event,
    user,
    isSignedIn,
    classes,
    history,
    submitting,
    EventData,
    RecommendData,
    id,
    copyEventUrl,
    signIn,
  ])

  const requestCount = useMemo(() => {
    if (!event) return 0
    if (event.privacyStatus === 'private') return 0
    return event.attendeeIds.length - event.memberIds.length
  }, [event])

  const canShowMembers = useMemo(() => {
    if (!event) return false
    if (event.privacyStatus !== 'private') return true
    if (!user) return false
    if (event.creator.uid !== user.uid) return false
    return true
  }, [user, event])

  return (
    <>
      {event && (
        <Helmet>
          <title>{event.summary} | Lachsy</title>
          <meta name="description" content={event.description} />
        </Helmet>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        onExit={() =>
          history.goBack(history.prev.startsWith('/recommends') ? { tab: '募集中' } : null)
        }
        fullScreen
        transitionDuration={{
          enter: history.prev.startsWith('/recommends') ? 0 : 300,
          exit: 300,
        }}
      >
        <DialogTitle style={{ padding: 0 }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="back"
                onClick={() => setOpen(false)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="body1">イベント情報</Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        {event && (
          <DialogContent className={classes.content}>
            <List className={classes.list} style={{ backgroundImage: `url("${event.imageURL}")` }}>
              {event.imageURL && <ListItem style={{ height: window.innerWidth * (9 / 16) }} />}
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <LocalActivityIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography style={{ fontSize: 20, fontWeight: 'bold' }} variant="h1">
                      {event.summary}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                className={classes.listItem}
                button
                onClick={() => setActiveUser(event.creator)}
              >
                <ListItemIcon>
                  <EditIcon color="primary" />
                </ListItemIcon>
                <ListItemIcon style={{ minWidth: 32 }}>
                  <Avatar
                    style={{ width: 24, height: 24 }}
                    className={classes.avatar}
                    alt={event.creator.name}
                    src={event.creator.photoURL}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.onelineText}
                  primary={
                    <Typography color="textPrimary" variant="body2" className={classes.onelineText}>
                      {event.creator.name}
                    </Typography>
                  }
                />
              </ListItem>
              {event.privacyStatus !== 'private' && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon>
                    <GroupIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={`${event.memberIds.length} 人 / 定員 ${event.limit} 人`} />
                </ListItem>
              )}
              {canShowMembers && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon>
                    <AccountCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemAvatar style={{ flex: 1 }}>
                    <AvatarGroup>
                      {attendees.map(({ uid, name, photoURL }) => (
                        <Avatar className={classes.avatar} key={uid} alt={name} src={photoURL} />
                      ))}
                      {event.privacyStatus !== 'private' && event.attendeeIds.length > 5 && (
                        <Avatar className={classes.avatar}>+{event.attendeeIds.length - 5}</Avatar>
                      )}
                    </AvatarGroup>
                  </ListItemAvatar>
                  {user && event.creator.uid === user.uid && requestCount > 0 && (
                    <div className={classes.listItemBadge}>
                      <Typography variant="caption" className={classes.badge}>
                        {requestCount}
                      </Typography>
                    </div>
                  )}
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() =>
                        history.push({ pathname: `/events/${event.id}/members`, state: recommend })
                      }
                    >
                      <NavigateNextIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <ScheduleIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  className={classes.onelineText}
                  primary={
                    <Typography color="textPrimary" variant="body2" className={classes.onelineText}>
                      {date}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <SubjectIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={<Markdown source={event.description} className="markdown-body" />}
                />
              </ListItem>
              {event.privacyStatus !== 'private' && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon>
                    <PublicIcon color="primary" />
                  </ListItemIcon>
                  <TextField
                    inputRef={eventUrlRef}
                    variant="outlined"
                    size="small"
                    multiline
                    style={{ width: '100%' }}
                    value={window.location.href}
                  />
                  {document.queryCommandSupported('copy') && (
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={copyEventUrl}>
                        <AssignmentIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              )}
              {recommend && (
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => setRecommendModalOpen(true)}
                >
                  <ListItemIcon>
                    <AttachmentIcon color="primary" />
                  </ListItemIcon>
                  <ListItemCard
                    imageURL={recommend.imageURL}
                    primaryText={recommend.summary}
                    secondaryText={recommend.description}
                  />
                </ListItem>
              )}
              {event.privacyStatus === 'public' && tags.length > 0 && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon>
                    <LabelIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={tags} />
                </ListItem>
              )}
            </List>
            {buttons && <div className={classes.submit}>{buttons}</div>}
          </DialogContent>
        )}
        {recommend && (
          <Popup open={recommendModalOpen} handleClose={() => setRecommendModalOpen(false)}>
            <Recommend recommend={recommend} isPage={false} />
          </Popup>
        )}
        <CustomModal open={activeUser !== null} handleClose={() => setActiveUser(null)}>
          <Profile user={activeUser} buttons={[]} />
        </CustomModal>
        <MySnackbar message={snackbarMessage} onClose={() => setSnackbarMessage(null)} />
        <Switch>
          <Route path="/events/:id/members" component={EventMemberList} />
        </Switch>
      </Dialog>
    </>
  )
}
