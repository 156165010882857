import { FieldValue } from 'firestore/common'
import { FIRESTORE } from 'utils/config'

export const search = ({ db, queries }) => {
  let ref = db.collection('recommends')

  queries.forEach(q => {
    ref = ref.where(q.field, q.operator, q.value)
  })

  return ref.get().then(({ docs }) => docs.map(doc => ({ ...doc.data(), id: doc.id })))
}

export const list = ({ db, queries, startAfter }) => {
  let ref = db.collection('recommends')

  if (queries) {
    queries.forEach(q => {
      ref = ref.where(q.field, q.operator, q.value)
    })
  }

  ref = ref.orderBy('priority', 'desc').limit(FIRESTORE.LIMIT)

  if (startAfter) {
    ref = ref.startAfter(startAfter)
  }

  return ref.get().then(({ docs }) => docs)
}

export const get = ({ db, id }) => {
  return db
    .collection('recommends')
    .doc(id)
    .get()
    .then(doc => doc.data())
}

export const update = ({ db, data, id }) => {
  return db
    .collection('recommends')
    .doc(id)
    .update(data)
    .then(() => get({ db, id }))
}

export const addParticipant = ({ db, id, uid }) => {
  return db
    .collection('recommends')
    .doc(id)
    .update({
      participants: FieldValue.arrayUnion(uid),
    })
}
