import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Swipeable from 'react-swipeable-views'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import RecommendList from 'pages/RecommendList'
import { useHistory } from 'hooks/useHistory'

export default function Top() {
  const history = useHistory()
  const tabs = [
    'ピックアップ',
    'アミューズメント',
    '季節のイベント',
    'ミュージアム・展示',
    'フェス',
    'グルメ',
    '演劇・公演',
    'コンサート・ライブ',
    'オンラインイベント',
    'ウェビナー',
    '動画',
    '募集中',
  ]

  const [index, setIndex] = useState(() => {
    const { tab } = history.location.state || {}
    return tab ? tabs.indexOf(tab) : 0
  })

  return (
    <>
      <Helmet>
        <title>おすすめのイベント一覧 | Lachsy</title>
        <meta
          name="description"
          content="好みのイベントを探して、同じ趣味の人とマッチングしましょう！"
        />
      </Helmet>
      <Tabs
        value={tabs[index]}
        onChange={(e, v) => setIndex(tabs.indexOf(v))}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          background: '#fff',
          borderBottom: '1px solid #ddd',
        }}
      >
        {tabs.map((x, i) => (
          <Tab key={i} label={x} value={x} />
        ))}
      </Tabs>
      <Swipeable
        index={index}
        onChangeIndex={setIndex}
        style={{ flex: 1, background: '#ddd' }}
        resistance
      >
        {tabs.map((tab, i) => (
          <RecommendList key={i} category={tab} />
        ))}
      </Swipeable>
    </>
  )
}
