import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import moment from 'moment'
import * as yup from 'yup'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import LocalDiningIcon from '@material-ui/icons/LocalDining'
import SubjectIcon from '@material-ui/icons/Subject'
import TodayIcon from '@material-ui/icons/Today'
import ScheduleIcon from '@material-ui/icons/Schedule'
import AttachmentIcon from '@material-ui/icons/Attachment'
import PublicIcon from '@material-ui/icons/Public'
import ListItemCard from 'components/ListItemCard'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import ImageIcon from '@material-ui/icons/Image'
import PeopleIcon from '@material-ui/icons/People'
import LabelIcon from '@material-ui/icons/Label'

import ImageSelector from 'components/ImageSelector'
import { Timestamp } from 'firestore/common'
import { useRemoteConfig } from 'hooks/useRemoteConfig'
import { useFirebaseApp } from 'hooks/useFirebaseApp'
import { useEvent } from 'hooks/useEvent'
import { useHistory } from 'hooks/useHistory'
import { privacyStatusList } from 'utils/config'
import { useAuth } from 'hooks/useAuth'
import { postEvent } from 'utils/ApiRequest'

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  textField: {
    width: '100%',
  },
  start: {
    marginRight: theme.spacing(1),
  },
  caution: {
    display: 'block',
  },
  submitButton: {
    minWidth: 100,
  },
}))

const EventSchema = yup.object().shape({
  title: yup.string().required('必須項目です').max(20, '20 文字以内で入力してください'),
  date: yup
    .string()
    .required('必須項目です')
    .test('date', '本日以降を指定してください', function (value) {
      return moment(value).diff(moment(new Date()).startOf('day')) >= 0
    })
    .default(moment().add(7, 'day').format('YYYY-MM-DD')),
  start: yup.string().required('必須項目です').default(moment().format('HH:00')),
  end: yup
    .string()
    .required('必須項目です')
    .test('end', '開始時間より後に設定してください', function (value) {
      return value > this.parent.start
    })
    .default(moment().add(2, 'hour').format('HH:00')),
  description: yup.string().required('必須項目です').max(400, '400 文字以内で入力してください'),
  privacyStatus: yup.string().required('必須項目です').default('private'),
  limit: yup
    .number()
    .default(2)
    .typeError('半角数字で指定してください')
    .integer('整数で指定してください')
    .min(2, '定員は 2 名以上で指定してください')
    .test('limit', '非公開イベントの定員は 3 名以上で指定できません', function (value) {
      if (this.parent.privacyStatus === 'private' && value > 2) {
        return false
      }
      return true
    }),
  tags: yup.string(),
  recommendId: yup.string(),
  imageURL: yup.string().test('imageURL', '公開イベントは画像必須です', function (value) {
    if (this.parent.privacyStatus === 'public' && value === undefined) {
      return false
    }
    return true
  }),
})

export default function EventNew() {
  const classes = useStyles()
  const { eventCreateLimit } = useRemoteConfig()
  const { canCreateEvent } = useEvent()
  const [submitting, setSubmitting] = useState(false)
  const { state } = useLocation()
  const { recommend, attendees } = state || {}
  const { EventData } = useFirebaseApp()
  const [id, setId] = useState(null)
  const { register, handleSubmit, errors, getValues, setValue, control, watch } = useForm({
    defaultValues: EventSchema.cast(),
    validationSchema: EventSchema,
  })
  const { imageURL, privacyStatus } = watch(['imageURL', 'privacyStatus'])
  const history = useHistory()
  const { user } = useAuth()

  useEffect(() => {
    if (privacyStatus === 'private') {
      setValue('limit', 2)
    }
  }, [privacyStatus, setValue])

  useEffect(() => {
    EventData.generateId({}).then(setId)
  }, [EventData])

  useEffect(() => {
    register({ name: 'recommendId' })
    register({ name: 'imageURL' })
  }, [register])

  useEffect(() => {
    if (recommend) {
      setValue('recommendId', recommend.id)
    }
  }, [recommend, setValue])

  const onSubmit = async ({
    title,
    date,
    start,
    end,
    description,
    privacyStatus,
    recommendId,
    imageURL,
    limit,
    tags,
  }) => {
    setSubmitting(true)
    const zone = 'Asia/Tokyo'
    const snippet = { uid: user.uid, name: user.name, photoURL: user.photoURL }

    let data = {
      summary: title,
      start: Timestamp.fromDate(moment.tz(date + ' ' + start, zone).toDate()),
      end: Timestamp.fromDate(moment.tz(date + ' ' + end, zone).toDate()),
      creator: snippet,
      attendeeIds: [user.uid],
      attendees: {},
      memberIds: [user.uid],
      members: {},
      description: description,
      privacyStatus: privacyStatus,
      state: 'created',
      tags: tags !== undefined ? tags.split(',').filter(v => v !== '') : [],
      limit: limit,
    }
    data.attendees[user.uid] = snippet
    data.members[user.uid] = snippet

    // 招待者を先に指定している場合 attendees をセットして Matching に遷移
    if (state && state.attendees) {
      data.attendees = { ...data.attendees, ...state.attendees }
      data.attendeeIds = data.attendeeIds.concat(Object.keys(state.attendees))
      data.state = 'invited'
    }

    // 非公開イベント以外では候補者一覧のステップをスキップ
    if (privacyStatus !== 'private') {
      data.state = 'invited'
    }

    if (recommendId) {
      data.recommendId = recommendId
    }

    // 1. 画像がアップロード時は画像を保存
    // 2. Recommend 選択時は Recommend の画像を保存
    if (imageURL) {
      data.imageURL = imageURL
    } else if (recommend && recommend.imageURL) {
      data.imageURL = recommend.imageURL
    }

    await EventData.add({ id, data })

    if (process.env.NODE_ENV === 'production') {
      await postEvent({
        summary: data.summary,
        start: {
          dateTime: moment.tz(date + ' ' + start, zone).format(),
          timeZone: zone,
        },
        end: {
          dateTime: moment.tz(date + ' ' + end, zone).format(),
          timeZone: zone,
        },
        description: data.description,
      }).catch(error => {
        // ログイン時に権限を拒否ししていた場合にエラー発生する
        // 特にエラー対応をしない
        console.error(error)
      })
    }

    history.push({
      pathname: data.state === 'invited' ? `/events/${id}` : `/events/${id}/members`,
      state: state,
    })
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1100,
        background: '#fff',
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            style={{ position: 'absolute', top: 4, right: 4 }}
            onClick={() => history.goBack()}
          >
            <CloseIcon />
          </IconButton>
          <Typography align="center" style={{ marginTop: 2, flex: 1 }} variant="h6">
            募集の作成
          </Typography>
        </Toolbar>
      </AppBar>
      <List>
        <ListItem>
          <ImageIcon className={classes.icon} color="action" />
          {id && (
            <ImageSelector
              size={{ width: 320, height: 180 }}
              isAvatar={false}
              path={`events/${id}/image.jpg`}
              value={imageURL}
              handleChange={value => setValue('imageURL', value)}
              error={errors.imageURL}
            />
          )}
        </ListItem>
        <ListItem>
          <LocalDiningIcon className={classes.icon} color="action" />
          <TextField
            id="title"
            name="title"
            inputRef={register}
            label="タイトル"
            required
            variant="filled"
            className={classes.textField}
            error={errors.title !== undefined}
            helperText={errors.title && errors.title.message}
          />
        </ListItem>
        <ListItem>
          <TodayIcon className={classes.icon} color="action" />
          <TextField
            id="title"
            name="date"
            inputRef={register}
            label="日にち"
            type="date"
            required
            variant="filled"
            className={classes.textField}
            error={errors.date !== undefined}
            helperText={errors.date && errors.date.message}
          />
        </ListItem>
        <ListItem>
          <ScheduleIcon className={classes.icon} color="action" />
          <TextField
            id="start"
            name="start"
            inputRef={register}
            label="開始時間"
            type="time"
            required
            variant="filled"
            className={classes.textField + ' ' + classes.start}
            error={errors.start !== undefined}
            helperText={errors.start && errors.start.message}
            onChange={() => {
              const v = getValues()
              const startHour = Number(v.start.split(':')[0])
              const endHour = Number(v.end.split(':')[0])
              if (startHour > endHour) {
                const newEndHour = startHour < 22 ? startHour + 2 : 23
                setValue('end', `${newEndHour}:00`)
              }
            }}
          />
          <TextField
            id="end"
            name="end"
            inputRef={register}
            label="終了時間"
            type="time"
            required
            variant="filled"
            className={classes.textField}
            error={errors.end !== undefined}
            helperText={errors.end && errors.end.message}
          />
        </ListItem>
        <ListItem>
          <PublicIcon className={classes.icon} color="action" />
          {/* 先にユーザー選択している場合は非公開イベントのみ開けるようにする */}
          <FormControl
            required
            disabled={attendees !== undefined}
            variant="filled"
            style={{ flex: 1 }}
            error={errors.privacyStatus !== undefined}
          >
            <InputLabel id="privacy-status-label">公開設定</InputLabel>
            <Controller
              as={
                <Select labelId="privacy-status-label" id="privacy-status-select">
                  {privacyStatusList
                    .filter(({ label, value }) => {
                      // 乱立防止のため、Recommend 選択時は公開イベント作成ができない
                      if (recommend && value === 'public') return false
                      return true
                    })
                    .map(({ label, value }) => {
                      return (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      )
                    })}
                </Select>
              }
              onChange={([e]) => {
                return e.target.value
              }}
              control={control}
              name="privacyStatus"
            />
            {errors.privacyStatus && (
              <FormHelperText>{errors.privacyStatus.message}</FormHelperText>
            )}
          </FormControl>
        </ListItem>
        <ListItem>
          <SubjectIcon className={classes.icon} color="action" />
          <TextField
            id="description"
            name="description"
            inputRef={register}
            label="詳細"
            required
            multiline
            rowsMax="4"
            variant="filled"
            placeholder="集合場所などを記入しましょう"
            className={classes.textField}
            error={errors.description !== undefined}
            helperText={errors.description && errors.description.message}
          />
        </ListItem>
        {['public', 'unlisted'].includes(privacyStatus) && (
          <ListItem>
            <PeopleIcon className={classes.icon} color="action" />
            <TextField
              id="title"
              name="limit"
              inputRef={register}
              label="定員"
              type="limit"
              required
              variant="filled"
              className={classes.textField}
              error={errors.limit !== undefined}
              helperText={errors.limit && errors.limit.message}
            />
          </ListItem>
        )}
        {privacyStatus === 'public' && (
          <ListItem>
            <LabelIcon className={classes.icon} color="action" />
            <TextField
              id="tags"
              name="tags"
              inputRef={register}
              label="タグ"
              placeholder="カンマ区切りで入力してください"
              variant="filled"
              className={classes.textField}
              error={errors.tags !== undefined}
              helperText={errors.tags && errors.tags.message}
            />
          </ListItem>
        )}
        {recommend && (
          <ListItem>
            <AttachmentIcon className={classes.icon} color="action" />
            <ListItemCard
              imageURL={recommend.imageURL}
              primaryText={recommend.summary}
              secondaryText={recommend.description}
            />
          </ListItem>
        )}
        <ListItem style={{ flexDirection: 'row-reverse' }}>
          <Typography className={classes.caution} align="right" variant="caption">
            ※ 1 日に作成できるイベントは {eventCreateLimit} 件です。
            {!canCreateEvent && (
              <>
                <br />
                <span style={{ color: 'red' }}>本日の作成上限に達しました</span>
              </>
            )}
          </Typography>
        </ListItem>
        <ListItem style={{ flexDirection: 'row-reverse' }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={!canCreateEvent || submitting}
            onClick={handleSubmit(onSubmit)}
          >
            登録
          </Button>
        </ListItem>
      </List>
    </div>
  )
}
