import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  card: {
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  cardMedia: {
    minWidth: theme.spacing(12),
    minHeight: theme.spacing(12),
    objectFit: 'cover',
  },
  cardContent: {
    width: 0, // FIXME: なぜかこれを指定しないと textOverflow が効かない
    flex: 1,
  },
  cardHeader: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(0.5),
  },
  cardText: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
}))

export default function ListItemCard({ primaryText, secondaryText, imageURL }) {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardMedia className={classes.cardMedia} image={imageURL} title={primaryText} />
      <CardContent className={classes.cardContent}>
        <Typography
          className={classes.cardHeader}
          color="textPrimary"
          variant="body2"
          style={{ fontWeight: 'bold' }}
        >
          {primaryText}
        </Typography>
        <Typography
          className={classes.cardText}
          component="p"
          color="textSecondary"
          variant="caption"
        >
          {secondaryText}
        </Typography>
      </CardContent>
    </Card>
  )
}
