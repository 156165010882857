import React, { useContext, createContext } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/remote-config'
import 'firebase/storage'
import 'firebase/firestore'
import * as UserData from 'firestore/UserData'
import * as EventData from 'firestore/EventData'
import * as RecommendData from 'firestore/RecommendData'
import { useLocation } from 'react-router-dom'

import { useHistory } from 'hooks/useHistory'
import { firebaseConfig } from 'utils/config'

const FirebaseAppContext = createContext()

const app = firebase.initializeApp(firebaseConfig)

if (process.env.NODE_ENV === 'development') {
  // emulator の functions と firestore を参照する
  // Reference: https://firebase.google.com/docs/emulator-suite/connect_and_prototype
  app.functions().useFunctionsEmulator('http://localhost:5001')
  app.firestore().settings({
    host: 'localhost:8080',
    ssl: false,
  })
  app.remoteConfig().settings = {
    minimumFetchIntervalMillis: 60 * 1000,
  }
}

const db = app.firestore()
const storage = app.storage().ref(process.env.NODE_ENV === 'production' ? '' : 'dev/')
const functions =
  process.env.NODE_ENV === 'production'
    ? firebase.app().functions('asia-northeast1')
    : firebase.functions()

export function FirebaseAppProvider({ children }) {
  const location = useLocation()
  const history = useHistory()

  const $ = func => async ({ ...args }) => {
    try {
      const result = await func({ db, ...args })
      return result
    } catch (error) {
      // TODO: エラー種別によってハンドリング
      // ex. 401, 404, 500
      console.error(error)
      if (location.pathname !== '/401') {
        history.push('/401')
      }
    }
  }

  const photoURLWithUid = uid => {
    let url = 'https://firebasestorage.googleapis.com/v0/b/schead-pj.appspot.com/o/'
    if (process.env.NODE_ENV === 'development') {
      url += 'dev%2F'
    }
    return url + `users%2F${uid}%2Fprofile.jpg?alt=media`
  }

  const modules = Object.entries({ UserData, RecommendData, EventData })
    .map(([key, funcs]) => {
      let module = {}
      module[key] = Object.entries(funcs)
        .map(([key, func]) => {
          let obj = {}
          obj[key] = $(func)
          return obj
        })
        .reduce((a, b) => ({ ...(a || {}), ...(b || {}) }))
      return module
    })
    .reduce((a, b) => ({ ...(a || {}), ...(b || {}) }))

  return (
    <FirebaseAppContext.Provider
      value={{
        storage,
        db,
        functions,
        photoURLWithUid,
        ...modules,
      }}
      children={children}
    />
  )
}

export function useFirebaseApp() {
  return useContext(FirebaseAppContext)
}
