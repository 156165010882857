import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

import ListItemImage from 'components/ListItemImage'
import { useFirebaseApp } from 'hooks/useFirebaseApp'
import { FieldValue } from 'firestore/common'
import { useAuth } from 'hooks/useAuth'
import { useHistory } from 'hooks/useHistory.js'

const useStyles = makeStyles(theme => ({
  list: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    borderBottom: '1px solid #ddd',
  },
}))

export default function RecommendListItem({ recommend }) {
  const classes = useStyles()
  const [item, setItem] = useState(recommend)
  const { RecommendData } = useFirebaseApp()
  const { user } = useAuth()
  const history = useHistory()

  const handleClickLike = () => {
    if (!user) return
    const data = {
      likes: item.likes.includes(user.uid)
        ? FieldValue.arrayRemove(user.uid)
        : FieldValue.arrayUnion(user.uid),
    }
    RecommendData.update({ id: item.id, data }).then(setItem)
  }

  return (
    <ListItem
      id={item.id}
      key={item.id}
      onClick={() => {
        const type = 'priority' in item ? 'recommend' : 'event'
        history.push(`/${type}s/${item.id}`)
      }}
      alignItems="center"
      button={true}
      className={classes.list}
    >
      <ListItemImage src={item.imageURL} alt={item.summary} style={{ width: 80, height: 60 }} />
      <ListItemText
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        primary={item.summary}
      />
      <ListItemSecondaryAction>
        {item.likes && (
          <>
            <IconButton onClick={handleClickLike} edge="end">
              {user && item.likes.includes(user.uid) ? (
                <FavoriteIcon style={{ fontSize: 16 }} color="primary" />
              ) : (
                <FavoriteBorderIcon style={{ fontSize: 16 }} color="primary" />
              )}
            </IconButton>
            <Typography style={{ fontSize: 12, marginLeft: 2 }} component="span" color="primary">
              {Math.min(item.likes.length, 999)}
            </Typography>
          </>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  )
}
