import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'

import Profile from 'components/Profile'
import UserListItem from 'components/UserListItem'
import CustomModal from 'components/CustomModal'
import { useHistory } from 'hooks/useHistory'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    borderTop: '1px solid #ddd',
    padding: theme.spacing(1, 2),
    textAlign: 'right',
  },
  button: {
    minWidth: 100,
  },
}))

export default function UserList({ users, title, canSubmit, handleSubmit, submitText }) {
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = useState(true)
  const [activeUser, setActiveUser] = useState(null)
  const [selected, setSelected] = useState([])
  const { id } = useParams()

  const handleDeselect = uid => {
    setSelected(selected => selected.filter(value => value !== uid))
    setActiveUser(null)
  }

  const handleSelect = uid => {
    var values = selected.slice()
    if (!values.includes(uid)) {
      values.push(uid)
      setSelected(values)
    }
    setActiveUser(null)
  }

  const profileButton = (() => {
    if (activeUser && selected.includes(activeUser.uid)) {
      return {
        label: '選択を解除する',
        handleClick: handleDeselect,
        color: 'default',
      }
    }
    return {
      label: '選択する',
      handleClick: handleSelect,
    }
  })()

  const listItems = (users || []).map(({ label, values, disabled }) => (
    <React.Fragment key={label}>
      {label.length > 0 && <ListSubheader>{label}</ListSubheader>}
      {values.map(elem => (
        <UserListItem
          key={elem.uid}
          user={elem}
          checked={selected.includes(elem.uid)}
          disabled={disabled}
          handleClick={() => setActiveUser(elem)}
          handleChange={() =>
            selected.includes(elem.uid) ? handleDeselect(elem.uid) : handleSelect(elem.uid)
          }
        />
      ))}
    </React.Fragment>
  ))

  const handleExited = () => {
    if (history.prev === '/events/new') {
      history.replace(`/events/${id}`)
      return
    }
    history.goBack()
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onExited={handleExited}
      fullScreen
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'left' }}
    >
      <DialogTitle style={{ padding: 0 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={() => setOpen(false)}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="body1">{title}</Typography>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <List>{listItems}</List>
        {canSubmit && (
          <div className={classes.submit}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={async () => {
                await handleSubmit(selected)
                setSelected([])
              }}
              disabled={selected.length === 0}
            >
              {submitText}
            </Button>
          </div>
        )}
      </DialogContent>
      <CustomModal open={activeUser !== null} handleClose={() => setActiveUser(null)}>
        <Profile user={activeUser} buttons={[profileButton]} />
      </CustomModal>
    </Dialog>
  )
}
