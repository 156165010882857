import React from 'react'

import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  tutorialText: {
    marginRight: theme.spacing(2),
    background: '#888',
    color: '#fff',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(0.5),
    '&::after': {
      right: 66,
      borderLeft: '8px solid #888',
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent',
    },
  },
  root: {
    position: 'absolute',
    bottom: theme.spacing(9),
    right: theme.spacing(2),
  },
  button: {
    background: theme.palette.primary.light,
  },
}))

export default function AddButton(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {props.showTutorialText && (
        <Typography
          component="span"
          variant="caption"
          color="textPrimary"
          className={classes.tutorialText}
        >
          自分で募集を作成する
        </Typography>
      )}
      <Fab aria-label="Add" color="primary" onClick={props.onClick} className={classes.button}>
        <AddIcon />
      </Fab>
    </div>
  )
}
