import { useMemo, useCallback } from 'react'
import { useLocation, useHistory as useDefaultHistory } from 'react-router-dom'

export const useHistory = () => {
  const history = useDefaultHistory()
  const location = useLocation()

  const entries = useMemo(() => {
    if (!location.state || !location.state.entries) return [location.pathname]
    return location.state.entries
  }, [location])

  const prev = useMemo(() => {
    if (entries.length < 2) return null
    return entries[entries.length - 2]
  }, [entries])

  const push = useCallback(
    args => {
      if (typeof args === 'string') {
        args = { pathname: args }
      }
      if (!args.state) {
        args.state = {}
      }
      args.state.entries = entries.concat([args.pathname])
      history.push(args.pathname, args.state)
    },
    [history, entries]
  )

  const replace = useCallback(
    pathname => {
      history.replace(pathname, { entries: entries.concat([pathname]) })
    },
    [history, entries]
  )

  const goBack = useCallback(
    args => {
      const pathname = prev || '/'
      const state = {
        ...(args || {}),
        entries: entries.length > 0 ? entries.slice(0, -1) : entries,
      }
      history.replace(pathname, state)
    },
    [entries, history, prev]
  )

  return { ...history, push, replace, goBack, entries, prev }
}
