import React from 'react'
import moment from 'moment-timezone'

import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

const useStyles = isMine =>
  makeStyles(theme => ({
    message: {
      display: 'flex',
      flexWrap: 'wrap',
      textAlign: 'left',
      flexDirection: isMine ? 'row-reverse' : 'row',
    },
    balloon: {
      position: 'relative',
      display: 'inline-block',
      background: '#eee',
      maxWidth: '50%',
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(1),
      fontSize: 14,
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        borderTop: '12px solid transparent',
        borderBottom: '12px solid transparent',
      },
      '&[data-side="left"]': {
        margin: theme.spacing(0, 1, 0, 2),
        '&::after': {
          left: -8,
          borderRight: '12px solid #eee',
        },
      },
      '&[data-side="right"]': {
        margin: theme.spacing(0, 2, 0, 1),
        '&::after': {
          right: -8,
          borderLeft: '12px solid #eee',
        },
      },
    },
    avatar: {
      minWidth: theme.spacing(5),
    },
  }))

export default function MessageListItem({ message, user, isMine, handleClick }) {
  const classes = useStyles(isMine)()
  const time = message.createdAt && moment(message.createdAt.toDate()).format('HH:mm')

  return (
    <ListItem key={message.createdAt} className={classes.message}>
      <ListItemAvatar className={classes.avatar}>
        <Avatar alt={user.name} src={user.photoURL} onClick={() => handleClick(user.uid)} />
      </ListItemAvatar>
      <div data-side={isMine ? 'right' : 'left'} className={classes.balloon}>
        <Typography variant="body2">{message.text}</Typography>
      </div>
      <Typography variant="caption" color="textSecondary">
        {time}
      </Typography>
    </ListItem>
  )
}
