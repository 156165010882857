import React, { useEffect } from 'react'

import { useAuth } from 'hooks/useAuth'
import CenteredRoot from 'components/CenteredRoot'

export default function Login() {
  const auth = useAuth()

  useEffect(() => {
    if (auth.isSignedIn === null) return
    auth.signOut()
  }, [auth])

  return <CenteredRoot />
}
