import React from 'react'
import EventImage from 'images/not_login_events.png'
import MessageImage from 'images/not_login_messages.png'
import Button from '@material-ui/core/Button'
import { useAuth } from 'hooks/useAuth'

export default function NotLogin({ event }) {
  const { signIn } = useAuth()
  return (
    <div
      style={{
        flex: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <img
        style={{
          flex: 1,
          width: '100%',
          maxHeight: window.innerHeight - 56 - 56 - 40 - 8 - 16,
          objectFit: 'contain',
        }}
        src={event ? EventImage : MessageImage}
        alt={event ? 'イベントでマッチ' : 'メッセージ'}
      />
      <div style={{ padding: 8 }}>
        <Button variant="outlined" color="primary" onClick={signIn}>
          GOOGLE でログイン
        </Button>
      </div>
    </div>
  )
}
