import React from 'react'

import Snackbar from '@material-ui/core/Snackbar'

export default function MySnackbar({ message, onClose }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={message}
      style={{ marginBottom: 8 }}
      open={message !== null}
      autoHideDuration={1000}
      onClose={onClose}
    />
  )
}
