import React, { useContext, createContext } from 'react'
//import * as firebase from 'firebase/app'
//import 'firebase/remote-config'

const RemoteConfigContext = createContext()

export function RemoteConfigProvider({ children }) {
  const data = {
    eventCreateLimit: 1,
    tags: [
      'アミューズメント',
      '季節のイベント',
      'ミュージアム・展示',
      'フェス',
      'グルメ',
      '演劇・公演',
      'コンサート・ライブ',
      'オンラインイベント',
      'ウェビナー',
      '動画',
    ],
  }
  // TODO: SEO の状況次第で復活。不要になったら constants に移動
  //const remoteConfig = firebase.remoteConfig()

  //useEffect(() => {
  //  remoteConfig
  //    .fetchAndActivate()
  //    .then(() => {
  //      const eventCreateLimit = remoteConfig.getNumber('eventCreateLimit')
  //      const tags = remoteConfig.getString('tags').split(',')
  //      setData({ eventCreateLimit, tags })
  //    })
  //    .catch(console.log)
  //}, [remoteConfig])

  return <RemoteConfigContext.Provider value={data} children={children} />
}

export function useRemoteConfig() {
  return useContext(RemoteConfigContext)
}
